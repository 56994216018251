import React, { Fragment, useState } from 'react';
// Tools
import PropTypes from 'prop-types';
import { getJwt } from '../../../../lib/auth';
import { getLocation, returnBrowser, returnOS } from '../../../helpers/helpers';
import { setFinishQuizCollab, setResponseQuizCollab } from '../../Models/FrontSurvey_model';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import $ from "jquery";

const Questions = ({ survey, idCollab }) => {

    const [counters, setCounters] = useState({
        answer_1: '',
        answer_2: '',
        answer_3: '',
        answer_4: '',
        answer_5: '',
        answer_6: '',
        answer_7: '',
        answer_8: '',
        answer_9: '',
        answer_10: '',
    });

    const [surveyValues, setSurveyValues] = useState([]);

    const counterAssignment = (num) => {
        let structure;
        switch (num) {
            case 1:
                structure = <span>{counters.answer_1.length}/250</span>;
                break;
            case 2:
                structure = <span>{counters.answer_2.length}/250</span>;
                break;
            case 3:
                structure = <span>{counters.answer_3.length}/250</span>;
                break;
            case 4:
                structure = <span>{counters.answer_4.length}/250</span>;
                break;
            case 5:
                structure = <span>{counters.answer_5.length}/250</span>;
                break;
            case 6:
                structure = <span>{counters.answer_6.length}/250</span>;
                break;
            case 7:
                structure = <span>{counters.answer_7.length}/250</span>;
                break;
            case 8:
                structure = <span>{counters.answer_8.length}/250</span>;
                break;
            case 9:
                structure = <span>{counters.answer_9.length}/250</span>;
                break;
            case 10:
                structure = <span>{counters.answer_10.length}/250</span>;
                break;
            default:
                structure = <span>0/250</span>;
                break;
        }
        return structure;
    }

    const sendResponse = (data) => {
        setResponseQuizCollab(data).then(response => {
            switch (response?.code) {
                case 1:
                    /* toastr.success('Respuesta guardada correctamente.'); */
                    break;

                case 99:
                    toastr.error('Ocurrió un error al guardar, verifique su conexión a internet.');
                    break;

                case 1000:
                    toastr.error('No se pudo validar los datos ingresados, contacte a su administrador.');
                    break;

                case 1001:
                    toastr.info('No existe su cuestionario, contacte a su administrador.');
                    break;

                default:
                    toastr.error('Ocurrió un error inesperado. Recargue su pagina y vuelva a intentarlo.');
                    break;
            }
        })
    }

    const handleChangeInput = async (survey, { target }) => {

        // Se crea el objeto con los datos a mandar
        const data = {
            idEnterprise: parseInt(getJwt('enterprise')),
            idQuizEnterprise: parseInt(survey.idQuizEnterprise),
            idQuestionQuiz: parseInt(survey.idQuestionQuiz),
            answerQuiz: target.value,
            idCollaborator: idCollab,
            coordinates: await getLocation.then(response => response).catch(error => error),
            browser: returnBrowser(),
            os: returnOS()
        };

        if (survey.idTypeQuestion === 1) { // Si el tipo de pregunta es abierta
            const index = surveyValues.findIndex(surveyValue => surveyValue.idQuestionQuiz === survey.idQuestionQuiz);
            if (index === -1) { // Si no existe la pregunta en el array se agrega
                setSurveyValues(prevSurveyValues => [...prevSurveyValues, data]);
            } else { // si existe la pregunta en el array se elimina y se pasa e nuevo objeto
                setSurveyValues([...surveyValues.filter(surveyValue => surveyValue.idQuestionQuiz !== survey.idQuestionQuiz), data]);
            }
        } else if (survey.idTypeQuestion === 4) { // Si el tipo de pregunta es checkbox
            const index = surveyValues.findIndex(surveyValue => surveyValue.idQuestionQuiz === survey.idQuestionQuiz);
            if (index === -1) { // Si no existe la pregunta en el array se agrega
                sendResponse(data);
                setSurveyValues(prevSurveyValues => [...prevSurveyValues, data]);
            } else { // si existe la pregunta en el array se elimina y se pasa eL nuevo objeto
                sendResponse({ ...data, answerQuiz: `${surveyValues[index].answerQuiz},${target.value}` })
                setSurveyValues([
                    ...surveyValues.filter(surveyValue => surveyValue.idQuestionQuiz !== survey.idQuestionQuiz),
                    { ...data, answerQuiz: `${surveyValues[index].answerQuiz},${target.value}` }
                ]);
            }
        } else {
            const index = surveyValues.findIndex(surveyValue => surveyValue.idQuestionQuiz === survey.idQuestionQuiz);
            if (index === -1) { // Si no existe la pregunta en el array se agrega
                sendResponse(data);
                setSurveyValues(prevSurveyValues => [...prevSurveyValues, data]);
            } else { // si existe la pregunta en el array se elimina y se pasa eL nuevo objeto
                sendResponse(data);
                setSurveyValues([
                    ...surveyValues.filter(surveyValue => surveyValue.idQuestionQuiz !== survey.idQuestionQuiz),
                    data
                ]);
            }
        }
    }

    const handleClickFinish = (e) => {
        e.preventDefault();
        $('#submitBtn').attr('type', 'button')
        $('#submitBtn').attr('disabled', 'true')
        if (surveyValues.length !== survey.length) {
            toastr.warning('Hay preguntas sin contestar, conteste todas las preguntas para finalizar.');
            $('#submitBtn').attr('type', 'submit')
            $('#submitBtn').attr('disabled', 'false')
        } else {
            const data = {
                idEnterprise: parseInt(getJwt('enterprise')),
                idQuizEnterprise: parseInt(survey[0].idQuizEnterprise),
                idCollaborator: idCollab,
                answers: surveyValues
            }
            setFinishQuizCollab(data).then(response => {
                switch (response?.code) {
                    case 1:
                        toastr.success('Encuesta guardada Satisfactoriamente.');
                        sessionStorage.setItem('frontSurvey', 1);
                        $('#frontSurvey').hide();
                        break;
                    case 99:
                        toastr.error('Ocurrió un error al guardar, verifique su conexión a internet.');
                        break;

                    case 1000:
                        toastr.error('No se pudo validar los datos ingresados, contacte a su administrador.');
                        break;

                    case 1001:
                        toastr.info('No existe su cuestionario, contacte a su administrador.');
                        break;

                    default:
                        toastr.error('Ocurrió un error inesperado. Recargue su pagina y vuelva a intentarlo.');
                        break;
                }
                $('#submitBtn').attr('type', 'submit')
                $('#submitBtn').attr('disabled', 'false')
            })
        }
    }

    return (
        <div className='col-12'>
            <form onSubmit={handleClickFinish}>
                {survey.map((data, index) => (
                    <div className='col-12 mb-2'>
                        <div className='form-group'>
                            <label className="form-label font-weight-bold">{index + 1 + ') '}{data.description}</label>
                            {
                                data.idTypeQuestion === 1 ? (
                                    <Fragment>
                                        <textarea
                                            className="form-control"
                                            rows="2"
                                            id={'answer_' + data.idOrder}
                                            name={'answer_' + data.idOrder}
                                            onChange={e => {
                                                if (e.target.value.trim() !== '') {
                                                    setCounters({
                                                        ...counters,
                                                        [e.target.name]: e.target.value
                                                    });
                                                    handleChangeInput(data, e);
                                                } else {
                                                    e.preventDefault();
                                                }
                                            }}
                                            style={{ resize: 'none' }}
                                            maxLength='250'
                                            required
                                        ></textarea>
                                        {counterAssignment(data.idOrder)}
                                    </Fragment>
                                ) : (
                                    data.idTypeQuestion === 2 || data.idTypeQuestion === 3 ? (
                                        <div className='row'>
                                            {
                                                data.answers.map((answer, index) => (
                                                    <div
                                                        className={data.idTypeQuestion === 2 && answer.description.length < 10 ? 'col-sm-12 col-md-3 col-lg-3' : 'col-sm-12 col-md-6 col-lg-6'}
                                                        key={'answer-' + data.idOrder + '-option-' + index}
                                                    >
                                                        <div className="form-check">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input pointer"
                                                                id={'answer-' + data.idOrder + '-option-' + index}
                                                                name={'answer-' + data.idOrder}
                                                                value={answer.idAnswerQuiz}
                                                                onChange={(e) => handleChangeInput(data, e)}
                                                                required
                                                            />
                                                            <label
                                                                className="form-check-label pointer"
                                                                htmlFor={'answer-' + data.idOrder + '-option-' + index}
                                                            ><span className='fw-bold'>{index === 0 ? ('a) ') : (index === 1 ? ('b) ') : (index === 2 ? ('c) ') : (index === 3 ? ('d) ') : (index === 4 ? ('e) ') : (null)))))}</span>{answer.description}</label>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ) : (
                                        data.idTypeQuestion === 4 ? (
                                            <div className='row'>
                                                {
                                                    data.answers.map((answer, index) => (
                                                        <div
                                                            className='col-sm-12 col-md-6 col-lg-6'
                                                            key={'answer-' + data.idOrder + '-option-' + index}
                                                        >
                                                            <div className="form-check">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input pointer"
                                                                    id={'answer-' + data.idOrder + '-option-' + index}
                                                                    name={'answer-' + data.idOrder + '-option-' + index}
                                                                    value={answer.idAnswerQuiz}
                                                                    onChange={(e) => handleChangeInput(data, e)}
                                                                />
                                                                <label
                                                                    className="form-check-label pointer"
                                                                    htmlFor={'answer-' + data.idOrder + '-option-' + index}
                                                                ><span className='fw-bold'>{index === 0 ? ('a) ') : (index === 1 ? ('b) ') : (index === 2 ? ('c) ') : (index === 3 ? ('d) ') : (index === 4 ? ('e) ') : (null)))))}</span>{answer.description}</label>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        ) : (
                                            data.idTypeQuestion === 5 ? (
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    id={'answer-' + data.idOrder}
                                                    name={'answer-' + data.idOrder}
                                                    onChange={(e) => handleChangeInput(data, e)}
                                                    required
                                                    onKeyDown={(e) => {
                                                        e.preventDefault()
                                                    }}
                                                />
                                            ) : (
                                                null
                                            )
                                        )
                                    )
                                )
                            }
                        </div>
                    </div>
                ))
                }
                <div className='d-flex justify-content-center'>
                    <button
                        type='submit'
                        className='btn btn-sm bg-pink-iam text-white pointer'
                        id='submitBtn'
                    >
                        Finalizar
                    </button>
                </div>
            </form>
        </div>
    );
};

Questions.propTypes = {
    survey: PropTypes.array.isRequired,
};

export default Questions;
