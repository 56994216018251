import React from 'react'
import $ from "jquery"

const ATSFRP = ({
    checkboxVal,
    checkboxFunc,
    enterpriseLogo,
}) => {
    return (
        <div
            className="modal"
            id="modal-1"
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            data-backdrop="false"
        >
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div
                    className="modal-content col-sx-10 mx-auto"
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                    }}
                >
                    <div className="modal-body modal-div-bodered container-style-1 pb-5">
                        <div className="col-12 d-flex justify-content-end">
                            <img
                                src={enterpriseLogo}
                                className="w-25"
                                alt='logo'
                            />
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                            <img
                                src="../img/Mensaje-encuesta_1.png"
                                className="w-60"
                                alt='logo'
                            />
                        </div>
                        <div className="col-12 font-medium-1 mt-1">
                            <p className="text-justify">En la primera parte encontrarás el cuestionario donde se identifican los <span className="text-pink-mine font-weight-bold">Acontecimientos Traumáticos Severos (ATS) durante tu jornada o con motivo del trabajo</span> que desempeñas actualmente. Posteriormente te preguntaremos por los ATS pero ocurridos <span className="text-pink-mine font-weight-bold">fuera de tu jornada laboral o en tu trabajo anterior.</span> </p>
                            <div className="col-12 pl-5">Ambos cuestionarios de Acontecimientos Traumáticos Severos contienen 4 secciones:
                                <p className='mt-1'>1. El tipo de acontecimiento <span className="font-weight-bold">(no importa hace cuanto lo sufriste)</span></p>
                                <p>2. Recuerdos persistentes sobre el acontecimiento  <span className="font-weight-bold">(durante el último mes)</span></p>
                                <p>3. Circunstancias parecidas o asociadas al acontecimiento  <span className="font-weight-bold">(durante el último mes)</span></p>
                                <p>4. Las afectaciones <span className="font-weight-bold">(durante el último mes)</span></p>
                            </div>
                            <p className="text-justify">En la segunda parte hacemos el <span className="text-pink-mine font-weight-bold">diagnóstico de los Factores de Riesgos Psicosocial</span> en tu centro de trabajo <span className="font-weight-bold">(considera las condiciones de los últimos dos meses).</span></p>
                            <p className="text-justify">Es importante que estés concentrado y <span className="text-pink-mine font-weight-bold">seas sincero</span> al momento de responder, recuerda que <span className="text-pink-mine font-weight-bold">no hay respuestas correctas o incorrectas.</span></p>
                            <p className="text-justify">Estos cuestionarios dan <span className="text-pink-mine font-weight-bold">cumplimiento a la NOM-035</span> de la Secretaría del Trabajo, son de <span className="text-pink-mine font-weight-bold">carácter obligatorio,</span> la información obtenida será tratada de manera confidencial y los resultados serán exclusivamente con <span className="text-pink-mine font-weight-bold">fines de mejora</span> en las condiciones del ambiente de trabajo.</p>
                            <p className="text-justify">Es necesario responder todos los reactivos para que el sistema nos permita obtener resultados confiables.</p>
                            <p className="text-justify">Agradecemos tu colaboración</p>
                        </div>
                        <div className="col-12 mt-1">
                            <div className="form-group form-check">
                                <label className="form-check-label pointer font-medium-1">
                                    <input type="checkbox" className="form-check-input" value="" onChange={() => checkboxFunc()} /> Al dar click aceptas nuestro <span className="text-blue font-weight-bold" onClick={() => window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf")}>aviso de privacidad</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-12 mt-1">
                            {checkboxVal === true && (
                                <button
                                    className="btn btn-sm btn-blue-3 float-right font-medium-1"
                                    onClick={() => $('#modal-1').hide()}
                                >Encuesta</button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ATSFRP