import React from 'react';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
// API calls
import { saveResponseEE } from '../Models/SurveysModel';

const ATSSection2 = ({
    section_2,
    setSection_2,
    setSection_2Complete,
    todayDate,
    coordinates,
    OS,
    browser,
    idEnterprise,
    idGroup,
    idCollab }) => {

    const chooseAnswer = e => {
        e.preventDefault();
        try {
            // Variables
            let id = e.target.id;
            let stringSplited = id.split("-");
            let questionId = parseInt(stringSplited[0]);
            let answerId = parseInt(stringSplited[1]);
            let questions = [];
            let description = "";
            let idsection = 0;
            // Fill question array
            for (let x in section_2) {
                description = section_2[x].description;
                idsection = section_2[x].idsection;
                for (let y in section_2[x].questions) {
                    questions.push(section_2[x].questions[y]);
                }
            }
            // Validate what answer was selected
            saveResponseEE(answerId, questionId, idEnterprise, idGroup, idCollab, todayDate, coordinates, browser, OS, "", "").then((response) => {
                if (response) {
                    if (response.code === 1) {
                        if (answerId === 1) {
                            let allYes = 0;
                            let allNo = 0;
                            for (let x in questions) {
                                if (questions[x].idquestion === questionId) {
                                    for (let y in questions[x].answers) {
                                        if (questions[x].answers[y].idanswer === 1) {
                                            questions[x].answers[y].check = 1;
                                        } else {
                                            questions[x].answers[y].check = 0;
                                        }
                                    }
                                }
                            }
                            let questionAnswered = [{
                                description: description,
                                idsection: idsection,
                                questions: questions
                            }];
                            setSection_2(questionAnswered);
                            // Count all the yes and no answers
                            for (let x in questions) {
                                for (let y in questions[x].answers) {
                                    if (questions[x].answers[y].idanswer === 1 && questions[x].answers[y].check === 1) {
                                        allYes = allYes + 1;
                                    }
                                    if (questions[x].answers[y].idanswer === 2 && questions[x].answers[y].check === 1) {
                                        allNo = allNo + 1;
                                    }
                                }
                            }
                            let unanswerSection_2 = questions.length;
                            let totalAnsweredSection_2 = allYes + allNo;
                            let totalUnanswerSection_2 = unanswerSection_2 - totalAnsweredSection_2;
                            if (totalUnanswerSection_2 === 0) {
                                setSection_2Complete(true);
                            }
                        } else {
                            let allYes = 0;
                            let allNo = 0;
                            for (let x in questions) {
                                if (questions[x].idquestion === questionId) {
                                    for (let y in questions[x].answers) {
                                        if (questions[x].answers[y].idanswer === 1) {
                                            questions[x].answers[y].check = 0;
                                        } else {
                                            questions[x].answers[y].check = 1;
                                        }
                                    }
                                }
                            }
                            let questionAnswered = [{
                                description: description,
                                idsection: idsection,
                                questions: questions
                            }];
                            setSection_2(questionAnswered);
                            // Count all the yes and no answers
                            for (let x in questions) {
                                for (let y in questions[x].answers) {
                                    if (questions[x].answers[y].idanswer === 1 && questions[x].answers[y].check === 1) {
                                        allYes = allYes + 1;
                                    }
                                    if (questions[x].answers[y].idanswer === 2 && questions[x].answers[y].check === 1) {
                                        allNo = allNo + 1;
                                    }
                                }
                            }
                            let unanswerSection_2 = questions.length;
                            let totalAnsweredSection_2 = allYes + allNo;
                            let totalUnanswerSection_2 = unanswerSection_2 - totalAnsweredSection_2;
                            if (totalUnanswerSection_2 === 0) {
                                setSection_2Complete(true);
                            }
                        }
                    } else {
                        toastr.error("No se guardó correctamente la respuesta. Intentelo nuevamente.", "¡Ooops!");
                    }
                } else {
                    toastr.error("No se guardó correctamente la respuesta. Se tienen problemas con el servidor.", "¡Ooops!");
                }
            });
            let idN = parseInt(e.target.id, 10);
            idN = idN + 1;
            // element which needs to be scrolled to
            const element = document.getElementById(`${idN}`);
            // scroll to element
            element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
            {
                section_2.map((data) => (
                    <div className="row" id={data.idsection} key={data.idsection}>
                        <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto font-medium-3">
                            <span className="text-base font-weight-bold">{data.description}</span>
                        </div>
                        {data.questions.map((question) => (
                            <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto mt-1 font-medium-1" id={question.idquestion} key={question.idquestion}>
                                <div className="card shadow-card-1">
                                    <div className="card-header bg-blue-guay-2 py-1">
                                        <span>{question.description}</span>
                                    </div>
                                    <div className="card-body">
                                        {question.answers.map((answer) => (
                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 padding-small-2" key={question.idquestion + "-" + answer.idanswer}>
                                                {answer.check === 1
                                                    ?
                                                    (
                                                        <span className="pointer" id={question.idquestion + "-" + answer.idanswer} key={question.idquestion + "-" + answer.idanswer} onClick={chooseAnswer} ><i className="ft ft-check-circle text-base-guay mr-1 align-middle icon-size-small padding-small" id={question.idquestion + "-" + answer.idanswer} />{answer.description}</span>
                                                    )
                                                    :
                                                    (
                                                        <span className="pointer" id={question.idquestion + "-" + answer.idanswer} key={question.idquestion + "-" + answer.idanswer} onClick={chooseAnswer} ><i className="ft ft-circle mr-1 align-middle text-base icon-size-small padding-small" id={question.idquestion + "-" + answer.idanswer} />{answer.description}</span>
                                                    )
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))
            }
        </div>
    )
}

export default ATSSection2;
