import React, { useState, useEffect } from 'react';
import { BootstrapTable, TableHeaderColumn, SearchField } from 'react-bootstrap-table';
import $ from "jquery";
import { getUploadedCollaborators, setUpdateCollaborators, delListCollaborators,getCollabByText,deleteReplaceBoss } from '../../Models/Collaborators_model';
import { getEnterpriseJob, getBossEnterprise, getBranches } from '../../Models/Enterprise_model';
import { getEnterprisesCategory, getEnterpriseArea } from '../../Models/Catalog_model';
import { getJwt } from "../../../../lib/auth";
import toastr from 'toastr';
import 'toastr/build/toastr.css';
import LoaderApp from '../../LoaderApp';
import ModalUploadCollab from './ModalUploadCollab';
import HistoryCollab from './HistoryCollab';
import swal from 'sweetalert';
import DentalForm from './DentalForm';
import FilterColumn from './FilterColumn';
// TODO: API Calls
import {
  getGenderExtCatalog,
  // getDentalPlanExtCatalog,
} from '../../Models/Catalog_model'
import { checkEnterpriseBenefit } from '../../Models/BenefitsModel';

const ConfirmCollab = ({ setTitleSection, actualizarSteps, setCompletedSteps, justRead }) => {

  const [dataCollab, setDataCollab] = useState({
    collaboratorId: 0,
    namesCollab: "",
    lastName: "",
    secondLastName: "",
    email: "",
    cel: "",
    categoryEntId: 0, //Puesto homologado
    jobEntId: 0, //Cargo/Puesto
    areaEntId: 0,
    bossEntId: 0,
    branchEntId: 0,
    levelCollab: 0, // Key player
    type_ent_colab: 0, // Lider
    levelCollabR1: 0, // Key player
    levelCollabR2: 0, // Key player
    type_ent_colabR1: 0, // Lider
    type_ent_colabR2: 0, // Lider
    rfc: "",
    dateOfAdmission: "",
    customA: "", // Telemedicina
    customAR1: "", // Telemedicina
    customAR2: "", // Telemedicina
    customB: "",
    customC: "",
    customD: "",
    customE: "",
    customF: "",
    customG: "",
    curp: "",
    gender: "",
    birthdate: "",
  });
  const [dataCollabAux, setDataCollabAux] = useState({});
  const [loading, setLoading] = useState(true);
  const [collaborators, setCollaborators] = useState([]);
  const [listJobs, setListJobs] = useState([]);
  const [listCategories, setListCategories] = useState([]);
  const [listAreas, setListAreas] = useState([]);
  const [listBosses, setListBosses] = useState([]);
  const [listBranches, setListBranches] = useState([]);
  const [update, setupdate] = useState();
  /* const [deleteCollabData, setdeleteCollabData] = useState({}) */
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRows_2, setSelectedRows_2] = useState([]);
  const [selectedRows_3, setSelectedRows_3] = useState([]);
  const [listBosses_2, setListBosses_2] = useState([]);
  const [listDeleteCollaborators, setListDeleteCollaborators] = useState([]);
  const [showHistory, setShowHistory] = useState(0);

  const [currentNumCollabs, setCurrentNumCollabs] = useState(0);
  const [currentNumAreas, setCurrentNumAreas] = useState(0);
  const [currentNumBranches, setCurrentNumBranches] = useState(0);

  const [maxDate, setMaxDate] = useState("");
  const [minDate, setMinDate] = useState("");

  const { namesCollab, lastName, secondLastName, email, cel, categoryEntId, jobEntId, areaEntId, bossEntId, branchEntId,
    levelCollabR1, levelCollabR2, type_ent_colabR1, type_ent_colabR2, rfc, dateOfAdmission, customB, customAR1, customAR2,
    customC, customD, customE, customF, customG } = dataCollab;

  const [collaboratorsList, setCollaboratorsList] = useState([]);
  const [dataCollabNewLiders, setDataCollabNewLiders] = useState({})

  const [genderCat, setGenderCat] = useState([])
  // const [dentalCat, setDentalCat] = useState([])
  const [relationBenefitEnterprise, setRelationBenefitEnterprise] = useState(false)


  const [filterColumnsCat, setFilterColumnsCat] = useState([
    {
      name: 'cargo',
      description: 'Cargo',
      checked: false,
    },
    {
      name: 'area',
      description: 'Área',
      checked: false,
    },
    {
      name: 'branch',
      description: 'Centro de Trabajo',
      checked: false,
    },
    {
      name: 'teleasistencia',
      description: 'Teleasistencia',
      checked: false,
    },
    {
      name: 'curp',
      description: 'CURP',
      checked: true,
    },
    {
      name: 'gender',
      description: 'Género',
      checked: true,
    },
    {
      name: 'birthdate',
      description: 'Fecha de nacimiento',
      checked: true,
    },
    {
      name: 'rfc',
      description: 'RFC',
      checked: true,
    },
  ])

  const validateTemplateBoss = (array) => {
    const getTemplateBoss = array.filter(objeto => objeto.type_ent_colab === 1 && objeto.active !== 0);
    if (getTemplateBoss.length === 0) {
      toastr.info('La plantilla de colaboradores actual no contiene lideres activos.', '¡Ojo!');
    }
  }

  useEffect(() => {
    setTitleSection("Administración de Colaboradores");
  })

  useEffect(() => {
    if (listBosses_2.length > 0 ) {
      const nuevoArr = listBosses_2.map(data =>{
        return {
          idEnterprise: getJwt("enterprise"),
          idBossCollabDelete: data.collaboratorId,
          idBossCollabReplace: 0,
        }
      })
      setDataCollabNewLiders(nuevoArr)
    }else{
      setDataCollabNewLiders({})
    }
    
    
  }, [listBosses_2])

  useEffect(() => {
    getGenderExtCatalog().then(response => {
      setGenderCat(response.genderExtList)
    })
    checkEnterpriseBenefit(parseInt(getJwt("enterprise"))).then(response => {
      // console.log('response: ',response);
      if (response.validation) {
        setRelationBenefitEnterprise(response.data.benefit)
      }
    })
    // getDentalPlanExtCatalog().then(response => {
    //   setDentalCat(response.dentalPlanExtList)
    // })
  }, [])

  // useEffect(() => {
  //   console.log('dataCollabNewLiders: ',dataCollabNewLiders);
  
    
  // }, [dataCollabNewLiders])
  
  

  useEffect(() => {
    try {
      getUploadedCollaborators(getJwt("enterprise")).then((response) => {
        if (response) {
          if (response.code === 1) {
            let array = [];
            let array_2 = [];
            let array_3 = [];
            let array_4 = response.listCollab;
            for (let x in array_4) {
              if (array_4[x].active === 0) {
                array_3.push(array_4[x]);
              } else {
                array.push(array_4[x]);
              }
            }
            for (let x in array) {
              if (array[x].namesCollab === "" || array[x].lastName === "" || array[x].areaEntId === 0 || array[x].categoryEntId === 0 || array[x].branchEntId === 0) {
                array_2.push({ ...array[x], status: 'incorrecto' });
              } else {
                if (array[x].active === 2) {
                  array_2.push({ ...array[x], status: 'inactivo' });
                } else {
                  array_2.push({ ...array[x], status: 'correcto' });
                }
              }
            }
            getEnterprisesCategory(getJwt("enterprise")).then((response) => {
              if (response) {
                if (response.code === 1) {
                  for (let d in response.categorys) {
                    for (let x in array_2) {
                      if (response.categorys[d].categoryEntId === array_2[x].categoryEntId) {
                        array_2[x].categoryEntId = response.categorys[d].description;
                      }
                    }
                  }
                  for (let d in response.categorys) {
                    for (let x in array_3) {
                      if (response.categorys[d].categoryEntId === array_3[x].categoryEntId) {
                        array_3[x].categoryEntId = response.categorys[d].description;
                      }
                    }
                  }
                  setListCategories(response.categorys);
                }
              }
            });
            getEnterpriseArea(getJwt("enterprise")).then((response) => {
              if (response) {
                if (response.code === 1) {
                  for (let c in response.areas) {
                    for (let x in array_2) {
                      if (response.areas[c].areaId === array_2[x].areaEntId) {
                        array_2[x].areaEntId = response.areas[c].description;
                      }
                    }
                  }
                  for (let c in response.areas) {
                    for (let x in array_3) {
                      if (response.areas[c].areaId === array_3[x].areaEntId) {
                        array_3[x].areaEntId = response.areas[c].description;
                      }
                    }
                  }
                  setListAreas(response.areas);
                }
              }
            });
            getBossEnterprise(getJwt("enterprise")).then((response) => {
              if (response) {
                if (response.code === 1) {
                  for (let b in response.listBoss) {
                    for (let x in array_2) {
                      if (response.listBoss[b].bossEntId === array_2[x].bossEntId) {
                        array_2[x].bossEntId = response.listBoss[b].description;
                      }
                    }
                  }
                  for (let b in response.listBoss) {
                    for (let x in array_3) {
                      if (response.listBoss[b].bossEntId === array_3[x].bossEntId) {
                        array_3[x].bossEntId = response.listBoss[b].description;
                      }
                    }
                  }
                  setListBosses(response.listBoss);
                }
              }
            });
            getBranches(getJwt("enterprise")).then((response) => {
              if (response) {
                if (response.code === 1) {
                  for (let a in response.branches) {
                    for (let x in array_2) {
                      if (response.branches[a].branchEntId === array_2[x].branchEntId) {
                        array_2[x].branchEntId = response.branches[a].description;
                      }
                    }
                  }
                  for (let a in response.branches) {
                    for (let x in array_3) {
                      if (response.branches[a].branchEntId === array_3[x].branchEntId) {
                        array_3[x].branchEntId = response.branches[a].description;
                      }
                    }
                  }
                  setListBranches(response.branches);
                }
              }
            });
            setListDeleteCollaborators(array_3);
            setCollaborators(array_2);
            // Validar que la plantilla tenga lideres
            validateTemplateBoss(response.listCollab);
          }
        }
      });
      getEnterpriseJob(getJwt("enterprise")).then((response) => {
        if (response) {
          if (response.code === 1) {
            setListJobs(response.enterprisesJobs);
          }
        }
      });
      setLoading(false);
      let today = new Date();
      let min = today.getFullYear() - 120;
      let max = today.getFullYear();
      let mm = today.getMonth() + 1;
      let dd = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
      let mm_2 = mm < 10 ? "0" + mm : mm;
      setMinDate("" + min + "-" + mm_2 + "-" + dd + "");
      setMaxDate("" + max + "-" + mm_2 + "-" + dd + "");
    } catch (error) {
      console.log(error);
    }
    return () => {
      setupdate()
    }
  }, [update]);

  const numCollabs = collaborators.length;
  const numAreas = listAreas.length;
  const numBranches = listBranches.length;

  const deleteCollaborator = (cell, row, rowIndex) => {
    /* setdeleteCollabData({
      name: row.namesCollab,
      lastName: row.lastName,
      enterpriseId: row.enterpriseId,
      collaboratorId: row.collaboratorId,
      job: row.categoryEntId,
      area: row.areaEntId
    });
    $("#confirmDeleteCollab").show(); */
    setSelectedRows([
      row.collaboratorId
    ]);
    setSelectedRows_3([
      ...selectedRows_3,
      row
    ]);
    $("#deleteInactiveModal").show();
  }

  /* const swalDeleteCollaborator = (collaboratorObject) => {
    deleteCollaborators(collaboratorObject.enterpriseId, collaboratorObject.collaboratorId).then((response) => {
      $("#confirmDeleteCollab").hide();
      switch (response.code) {
        case 1:
          setLoading(true);
          toastr.success('El colaborador se eliminó correctamente.', '¡Exito!');
          getUploadedCollaborators(getJwt("enterprise")).then((response) => {
            if (response.code === 1) {
              setCollaborators(response.listCollab);
              setLoading(false);
            }
          });
          break;
        case 99:
          toastr.error("Error al eliminar el colaborador", "Error");
          break;
        case 1001:
          toastr.info("Este colaborador ya no existe en nuestros datos, recarga la pagina", "Ojo");
          break;
        case 1002:
          toastr.error("Este colaborador es jefe y cuenta con trabajadores a su cargo", "Ojo");
          break;
        default:
          toastr.error("Error Inesperado", "Error");
          break;
      }
    })
  } */
  const setIndividualDescription = (data) => {
    let array = data;
    let array_2 = collaborators;
    for (let a in listBranches) {
      if (listBranches[a].branchEntId === array.branchEntId) {
        array.branchEntId = listBranches[a].description;
      }
    }
    for (let b in listBosses) {
      if (listBosses[b].bossEntId === array.bossEntId) {
        array.bossEntId = listBosses[b].description;
      }
    }
    for (let c in listAreas) {
      if (listAreas[c].areaId === array.areaEntId) {
        array.areaEntId = listAreas[c].description;
      }
    }
    for (let d in listCategories) {
      if (listCategories[d].categoryEntId === array.categoryEntId) {
        array.categoryEntId = listCategories[d].description;
      }
    }
    for (let x in array_2) {
      if (array_2[x].collaboratorId === array.collaboratorId) {
        array_2[x] = array;
      }
    }
    setCollaborators(array_2);
  }
  /**
   * Function that set all data in dataCollab to show it in the modal
   * @param {*} row 
   */
  const setDataInModal = (data) => {
    try {
      let array = data;
      setDataCollabAux(data);
      for (let a in listBranches) {
        if (listBranches[a].description === array.branchEntId) {
          array.branchEntId = listBranches[a].branchEntId;
        }
      }
      for (let b in listBosses) {
        if (listBosses[b].description === array.bossEntId) {
          array.bossEntId = listBosses[b].bossEntId;
        }
      }
      for (let c in listAreas) {
        if (listAreas[c].description === array.areaEntId) {
          array.areaEntId = listAreas[c].areaId;
        }
      }
      for (let d in listCategories) {
        if (listCategories[d].description === array.categoryEntId) {
          array.categoryEntId = listCategories[d].categoryEntId;
        }
      }
      setDataCollab({
        collaboratorId: array.collaboratorId,
        namesCollab: array.namesCollab,
        lastName: array.lastName,
        secondLastName: array.secondLastName,
        email: array.email,
        cel: array.cel === undefined || array.cel === "undefined" ? ("") : array.cel,
        categoryEntId: array.categoryEntId, //Puesto homologado
        jobEntId: array.jobEntId, //Cargo/Puesto
        areaEntId: array.areaEntId,
        bossEntId: array.bossEntId,
        branchEntId: array.branchEntId,
        levelCollab: array.type_ent_colab, // Key player
        type_ent_colab: array.type_ent_colab, // Lider
        levelCollabR1: array.levelCollab === 1 ? "checked" : "", // Key player
        levelCollabR2: array.levelCollab === 2 ? "checked" : "", // Key player
        type_ent_colabR1: array.type_ent_colab === 1 ? "checked" : "", // Lider
        type_ent_colabR2: array.type_ent_colab === 2 ? "checked" : "", // Lider
        rfc: array.rfc,
        dateOfAdmission: array.dateOfAdmission === "" || array.dateOfAdmission === undefined || array.dateOfAdmission === "undefined" ? "" : getAdmissionDate(array.dateOfAdmission),
        customA: array.customA, // Telemedicina
        customAR1: array.customA === "1" ? "checked" : "", // Telemedicina
        customAR2: array.customA === "2" || array.customA === "" || array.customA === undefined ? "checked" : "", // Telemedicina
        customB: array.customB,
        customC: array.customC,
        customD: array.customD,
        customE: array.customE,
        customF: array.customF,
        customG: array.customG,
        curp: array.curp,
        gender: array.gender,
        birthdate: array.birthdate === "" || array.birthdate === undefined || array.birthdate === "undefined" ? "" : getFormateDate(array.birthdate),
      });
      openModal(2);
    } catch (error) {
      console.log(error);
    }
  }
  const getAdmissionDate = (admissionDate) => {
    let getObject = admissionDate;
    let obj = getObject.split(" ");
    return (obj[0]);
  }
  
  const getFormateDate = (date) => {
    if(date !== '' && date !== undefined && date !== 'undefined'){
      let getObject = date;
      let obj = getObject.split(" ");
      return (obj[0]);
    }else{
      return '';
    }
    
  }
  const setIcon1 = (cell, row, rowIndex) => {
    if (justRead) {
      return (
        <div>
          <i
            className='ft ft-trash-2 icon-medium-size text-base pointer'
            data-toggle="tooltip"
            data-placement="top"
            title="Eliminar colaborador"
          />
        </div>
      )
    } else {
      return (
        <div>
          <i
            onClick={() => deleteCollaborator(cell, row, rowIndex)}
            className='ft ft-trash-2 icon-medium-size text-red pointer'
            data-toggle="tooltip"
            data-placement="top"
            title="Eliminar colaborador"
          />
        </div>
      )
    }
  }
  /**
   * Function that show an icon in BootstrapTable
   * @param {*} row 
   * @returns 
   */
  const setIcon2 = (cell, row, enumObject, rowIndex) => {
    let data = row;
    if (justRead) {
      return (
        <div>
          <i
            className='ft ft-edit-2 icon-medium-size text-base pointer'
            data-toggle="tooltip"
            data-placement="top"
            title="Editar colaborador"
          />
        </div>
      )
    } else {
      return (
        <div>
          <i
            onClick={() => setDataInModal(data)}
            className='ft ft-edit-2 icon-medium-size text-blue pointer'
            data-toggle="tooltip"
            data-placement="top"
            title="Editar colaborador"
          />
        </div>
      )
    }
  }
  /**
   * Function that show an icon in BootstrapTable
   * @param {*} cell 
   * @returns 
   */
  const setData = (cell, row, enumObject, rowIndex) => {
    if (cell === "" || cell === 0) {
      return (
        <div>
          <i className='ft ft-alert-triangle icon-medium-size text-warning pointer'
            data-toggle="tooltip"
            data-placement="top"
            title="Ningún dato" />
        </div>
      )
    } else {
      return (cell)
    }
  }
  /**
   * 
   * @param {any} cell 
   * @param {object} row 
   * @param {*} enumObject 
   * @param {number} rowIndex 
   * @returns 
   */
  const setEmail = (cell, row, enumObject, rowIndex) => {
    /* let regex = /^\w+([\.-]?\w+)*@[a-zA-Z0-9_]+?\.[a-zA-Z0-9_]{2,12}.*$/ */
    // eslint-disable-next-line
    let regex = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
    if (cell === "" || cell === 0 || !regex.test(cell)) {
      return (
        <div>
          <i className='ft ft-alert-triangle icon-medium-size text-warning pointer'
            data-toggle="tooltip"
            data-placement="top"
            title="Ningún dato" />
        </div>
      )
    } else {
      return (cell)
    }
  }
  /**
   * Function that show an icon in BootstrapTable
   * @param {*} cell 
   * @returns 
   */
  const setCategoryEntId = (cell, row, enumObject, rowIndex) => {
    if (cell === "" || cell === 0) {
      return (
        <div>
          <i className='ft ft-alert-triangle icon-medium-size text-warning pointer'
            data-toggle="tooltip"
            data-placement="top"
            title="Ningún dato" />
        </div>
      )
    } else {
      return cell
    }
  }
  /**
   * Function that show an icon in BootstrapTable
   * @param {*} cell 
   * @returns 
   */
  const setAreaEntId = (cell, row, enumObject, rowIndex) => {
    if (cell === "" || cell === 0) {
      return (
        <div>
          <i className='ft ft-alert-triangle icon-medium-size text-warning pointer'
            data-toggle="tooltip"
            data-placement="top"
            title="Ningún dato" />
        </div>
      )
    } else {
      return cell
    }
  }
  /**
   * Function that show an icon in BootstrapTable
   * @param {*} cell 
   * @returns 
   */
  const setTeleasistencia = (cell, row, enumObject, rowIndex) => {
    if (cell === "" || cell === "2" || cell === undefined) {
      return (
        <span>No</span>
      )
    } else {
      return (
        <span>Si</span>
      )
    }
  }
  /**
   * Function that show an icon in BootstrapTable
   * @param {*} cell 
   * @returns 
   */
  const setBranchEntId = (cell, row, enumObject, rowIndex) => {
    if (cell === "" || cell === 0) {
      return (
        <div>
          <i className='ft ft-alert-triangle icon-medium-size text-warning pointer'
            data-toggle="tooltip"
            data-placement="top"
            title="Ningún dato" />
        </div>
      )
    } else {
      return cell
    }
  }
  /**
   * Function that show an icon in BootstrapTable
   * @param {*} cell 
   * @returns 
   */
  const setDataStatus = (cell, row, enumObject, rowIndex) => {
    if (cell === "incorrecto") {
      return (
        <div>
          <i className='ft ft-user-x icon-medium-size text-danger pointer'
            data-toggle="tooltip"
            data-placement="top"
            title="Incompleto" />
        </div>
      )
    } else {
      if (cell === "inactivo") {
        return (
          <div>
            <i className='ft ft-user-minus icon-medium-size text-yellow pointer'
              data-toggle="tooltip"
              data-placement="top"
              title="Inactivo" />
          </div>
        )
      } else {
        return (
          <div>
            <i className='ft ft-user-check icon-medium-size text-success pointer'
              data-toggle="tooltip"
              data-placement="top"
              title="Completo" />
          </div>
        )
      }
    }
  }
  /**
   * Function that show an icon in BootstrapTable
   * @param {*} cell 
   * @returns 
   */
  const setDataStatus_2 = (cell, row, enumObject, rowIndex) => {
    return (
      <div>
        <i className='ft ft-x-circle icon-medium-size text-danger pointer'
          data-toggle="tooltip"
          data-placement="top"
          title="Eliminado" />
      </div>
    )
  }

  /**
   * Set data in dataCollab
   * @param {*} e 
   */
  const getValue = e => {
    switch (e.target.name) {
      case "type_ent_colab":
        $("#type_ent_colab_l").removeClass("text-danger");
        $("#type_ent_colab_l").addClass("text-base");
        switch (parseInt(e.target.value)) {
          case 1:
            setDataCollab({
              ...dataCollab,
              [e.target.name]: parseInt(e.target.value),
              type_ent_colabR1: "checked",
              type_ent_colabR2: ""
            });
            break;
          case 2:
            setDataCollab({
              ...dataCollab,
              [e.target.name]: parseInt(e.target.value),
              type_ent_colabR1: "",
              type_ent_colabR2: "checked"
            });
            break;
          default:
            setDataCollab({
              ...dataCollab,
              [e.target.name]: parseInt(e.target.value),
              type_ent_colabR1: "",
              type_ent_colabR2: ""
            });
            break;
        }
        break;
      case "levelCollab":
        switch (parseInt(e.target.value)) {
          case 1:
            setDataCollab({
              ...dataCollab,
              [e.target.name]: parseInt(e.target.value),
              levelCollabR1: "checked",
              levelCollabR2: ""
            });
            break;
          case 2:
            setDataCollab({
              ...dataCollab,
              [e.target.name]: parseInt(e.target.value),
              levelCollabR1: "",
              levelCollabR2: "checked"
            });
            break;
          default:
            setDataCollab({
              ...dataCollab,
              [e.target.name]: parseInt(e.target.value),
              levelCollabR1: "",
              levelCollabR2: ""
            });
            break;
        }
        break;
      case "customA":
        switch (e.target.value) {
          case "1":
            setDataCollab({
              ...dataCollab,
              [e.target.name]: e.target.value,
              customAR1: "checked",
              customAR2: ""
            });
            break;
          case "2":
            setDataCollab({
              ...dataCollab,
              [e.target.name]: e.target.value,
              customAR1: "",
              customAR2: "checked"
            });
            break;
          default:
            setDataCollab({
              ...dataCollab,
              [e.target.name]: e.target.value,
              customAR1: "",
              customAR2: ""
            });
            break;
        }
        break;
      case "dateOfAdmission":
        $("#dateOfAdmission").removeClass("border-danger-guay");
        setDataCollab({
          ...dataCollab,
          [e.target.name]: e.target.value
        });
        break;
      case "cel":
        $("#cel").removeClass("border-danger-guay");
        if (e.target.value.length < 11) {
          setDataCollab({
            ...dataCollab,
            [e.target.name]: e.target.value
          });
        }
        break;
      case "rfc":
        $("#rfc").removeClass("border-danger-guay");
        if (e.target.value.length < 14) {
          setDataCollab({
            ...dataCollab,
            [e.target.name]: e.target.value
          });
        }
        break;
      case "email":
        let regex = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
        if (regex.test(e.target.value) || e.target.value === "") {
          $("#email").removeClass("border-danger-guay");
          setDataCollab({
            ...dataCollab,
            [e.target.name]: e.target.value
          });
        } else {
          $("#email").addClass("border-danger-guay");
          setDataCollab({
            ...dataCollab,
            [e.target.name]: e.target.value
          });
        }

        break;
      case "curp":
        $("#curp").removeClass("border-danger-guay");
        if (e.target.value.length < 19) {
          setDataCollab({
            ...dataCollab,
            [e.target.name]: e.target.value
          });
        }
        break;
      default:
        $("#" + e.target.name).removeClass("border-danger-guay");
        setDataCollab({
          ...dataCollab,
          [e.target.name]: e.target.value
        });
        break;
    }
  }
  /**
   * Set collaborator using setUpdateCollaborators method
   */
  const setNewCollab = e => {
    e.preventDefault();
    if (validatingFields() === true) {
      let data = {
        namesCollab: dataCollab.namesCollab,
        lastName: dataCollab.lastName,
        secondLastName: dataCollab.secondLastName,
        email: dataCollab.email,
        cel: dataCollab.cel,
        categoryEntId: parseInt(dataCollab.categoryEntId), //Puesto homologado
        jobEntId: parseInt(dataCollab.jobEntId), //Cargo/Puesto
        areaEntId: parseInt(dataCollab.areaEntId),
        bossEntId: parseInt(dataCollab.bossEntId),
        branchEntId: parseInt(dataCollab.branchEntId),
        levelCollab: dataCollab.levelCollab,
        type_ent_colab: dataCollab.type_ent_colab,
        rfc: dataCollab.rfc,
        enterpriseId: parseInt(getJwt("enterprise")),
        dateOfAdmission: dataCollab.dateOfAdmission,
        customA: dataCollab.customA,
        customB: dataCollab.customB,
        customC: dataCollab.customC,
        customD: dataCollab.customD,
        customE: dataCollab.customE,
        customF: dataCollab.customF,
        customG: dataCollab.customG,
        curp: dataCollab.curp,
        gender: dataCollab.gender,
        birthdate: dataCollab.birthdate,
      }
      console.log(data)
      setUpdateCollaborators(data).then((response) => {
        switch (response?.code) {
          case 1:
            toastr.success('El colaborador se registró correctamente.', '¡Exito!');
            setLoading(true);
            getUploadedCollaborators(getJwt("enterprise")).then((response) => {
              if (response.code === 1) {
                // Validar que la plantilla tenga lideres
                validateTemplateBoss(response.listCollab);
                setDescriptions(response.listCollab);
                setLoading(false);
                cleanDataCollab();
                $("#dataCollab").hide();
                $("#confirmation").show();
                setShowHistory(0);
              }
            });
            break;

          case 2:
            toastr.success('El colaborador se registró correctamente, pero no se logro actualizar el correo de su usuario', '¡Exito!');
            setLoading(true);
            getUploadedCollaborators(getJwt("enterprise")).then((response) => {
              if (response.code === 1) {
                // Validar que la plantilla tenga lideres
                validateTemplateBoss(response.listCollab);
                setDescriptions(response.listCollab);
                setLoading(false);
                cleanDataCollab();
                $("#dataCollab").hide();
                $("#confirmation").show();
                setShowHistory(0);
              }
            });
            break;

          case 1004:
            cleanDataCollab();
            toastr.info('El correo del colaborador ya se encuentra en uso', '¡Atención!');
            break;

          case 1005:
            cleanDataCollab();
            toastr.info('El correo que se quiere actualizar ya se encuentra en uso', '¡Atención!');
            break;

          default:
            cleanDataCollab();
            toastr.error('Hubo un problema. No se registro el colaborador correctamente.', '¡Ooops!');
            break;
        }
      });
    }
  }
  /**
  * Update collaborator using setUpdateCollaborators method
  */
  const updateCollaborator = () => {
    if (validatingFields() === true) {
      let data = {
        collaboratorId: dataCollab.collaboratorId,
        namesCollab: dataCollab.namesCollab,
        lastName: dataCollab.lastName,
        secondLastName: dataCollab.secondLastName,
        email: dataCollab.email,
        cel: dataCollab.cel,
        categoryEntId: parseInt(dataCollab.categoryEntId), //Puesto homologado
        jobEntId: parseInt(dataCollab.jobEntId), //Cargo/Puesto
        areaEntId: parseInt(dataCollab.areaEntId),
        bossEntId: parseInt(dataCollab.bossEntId),
        branchEntId: parseInt(dataCollab.branchEntId),
        levelCollab: dataCollab.levelCollab,
        type_ent_colab: dataCollab.type_ent_colab,
        rfc: dataCollab.rfc,
        enterpriseId: parseInt(getJwt("enterprise")),
        dateOfAdmission: dataCollab.dateOfAdmission,
        customA: dataCollab.customA,
        customB: dataCollab.customB,
        customC: dataCollab.customC,
        customD: dataCollab.customD,
        customE: dataCollab.customE,
        customF: dataCollab.customF,
        customG: dataCollab.customG,
        curp: dataCollab.curp,
        gender: dataCollab.gender,
        birthdate: dataCollab.birthdate !== '' ? getFormateDate(dataCollab.birthdate) : '',
      }
      setUpdateCollaborators(data).then((response) => {
        switch (response?.code) {
          case 1:
            toastr.success('El colaborador se registró correctamente.', '¡Exito!');
            setLoading(true);
            getUploadedCollaborators(getJwt("enterprise")).then((response) => {
              if (response.code === 1) {
                // Validar que la plantilla tenga lideres
                validateTemplateBoss(response.listCollab);
                setDescriptions(response.listCollab);
                setLoading(false);
                cleanDataCollab();
                $("#dataCollab").hide();
              }
            });
            break;

          case 2:
            toastr.success('El colaborador se registró correctamente, pero no se logro actualizar el correo de su usuario', '¡Exito!');
            setLoading(true);
            getUploadedCollaborators(getJwt("enterprise")).then((response) => {
              if (response.code === 1) {
                // Validar que la plantilla tenga lideres
                validateTemplateBoss(response.listCollab);
                setDescriptions(response.listCollab);
                setLoading(false);
                cleanDataCollab();
                $("#dataCollab").hide();
              }
            });
            break;

          case 1004:
            cleanDataCollab();
            toastr.info('El correo del colaborador ya se encuentra en uso', '¡Atención!');
            break;

          case 1005:
            cleanDataCollab();
            toastr.info('El correo que se quiere actualizar ya se encuentra en uso', '¡Atención!');
            break;

          default:
            toastr.error('Hubo un problema. No se registro el colaborador correctamente.', '¡Ooops!');
            cleanDataCollab();
            break;
        }
      });
    }
  }
  /**
   * 
   * @param {event} e 
   */
  const getCollaborator = e => {
    e.preventDefault();
    let value = e.target.value;
    let oldLeaderCollabId = e.target.id
    document.querySelector(`#${oldLeaderCollabId}`).value=value
    if (value === "" || e.target.value.length === 0) {
        // console.log('esta vacio');
        $(`#div-${oldLeaderCollabId}`).attr('hidden', true)
        setCollaboratorsList([]);
        let parameter = oldLeaderCollabId.split("-");
        const newDataLeaders1 = dataCollabNewLiders.map(data => {
          if (data.idBossCollabDelete === parseInt(parameter[1])) {
                return {
                  idEnterprise: getJwt("enterprise"),
                  idBossCollabDelete: data.idBossCollabDelete,
                  idBossCollabReplace: 0,
                } 
          }else{
            return {
              idEnterprise: getJwt("enterprise"),
              idBossCollabDelete: data.idBossCollabDelete,
              idBossCollabReplace: data.idBossCollabReplace,
            } 
          }
        })
        setDataCollabNewLiders(newDataLeaders1)

    } else {
        getCollabByText(getJwt("enterprise"), value).then((response) => {
            switch (response.code) {
                case 1:
                    // console.log('se debe mostrar');
                    $(`#div-${oldLeaderCollabId}`).attr('hidden', false)
                    setCollaboratorsList(response.listCollab);
                    break;
                default:
                    $(`#div-${oldLeaderCollabId}`).attr('hidden', true)
                    setCollaboratorsList([]);
                    break;
            }
        });
    }
  }

  /**
     * 
     * @param {Event} e 
     * @param {string} name 
     * @param {string} lastName 
     * @param {string} secondLastName 
     * @param {string} rfc 
     * @param {int} collaboratorId 
     */
  const selectCollaborator = (e, name, lastName, secondLastName, newLeaderCollabId,oldLeaderCollabId) => {
    e.preventDefault();
    // console.log('e: ',e.target);
    $(`#div-collaborator-${oldLeaderCollabId}`).attr('hidden', true)
    setCollaboratorsList([]);
    const arrLeadersIds = listBosses_2.map(data => data.collaboratorId)
    const arrLeadersExist = arrLeadersIds.indexOf(newLeaderCollabId)
    // console.log('arrLeadersIds: ', arrLeadersIds,' arrLeadersExist: ',arrLeadersExist);

    const newDataLeaders = dataCollabNewLiders.map(data => {
      if (data.idBossCollabDelete === oldLeaderCollabId && arrLeadersExist === -1) {
          if(newLeaderCollabId > 0){
            if(oldLeaderCollabId === newLeaderCollabId){
              toastr.info("No puedes seleccionar el mismo líder.", "Ojo");
              return {
                idEnterprise: getJwt("enterprise"),
                idBossCollabDelete: data.idBossCollabDelete,
                idBossCollabReplace: 0,
              } 

            }else{
              return {
                idEnterprise: getJwt("enterprise"),
                idBossCollabDelete: data.idBossCollabDelete,
                idBossCollabReplace: newLeaderCollabId,
              } 

            }

          }else{
            return {
              idEnterprise: getJwt("enterprise"),
              idBossCollabDelete: data.idBossCollabDelete,
              idBossCollabReplace: 0,
            } 
          }
      }else if(data.idBossCollabDelete === oldLeaderCollabId && arrLeadersExist > -1){
        toastr.info("No puedes seleccionar un líder que seleccionaste para eliminar.", "Ojo");
        return {
          idEnterprise: getJwt("enterprise"),
          idBossCollabDelete: data.idBossCollabDelete,
          idBossCollabReplace: 0,
        } 
         
      }else{
        return {
          idEnterprise: getJwt("enterprise"),
          idBossCollabDelete: data.idBossCollabDelete,
          idBossCollabReplace: data.idBossCollabReplace,
        }
      }
    })
    setDataCollabNewLiders(newDataLeaders)
    document.querySelector(`#collaborator-${oldLeaderCollabId}`).value= oldLeaderCollabId === newLeaderCollabId || arrLeadersExist > -1 ? '': `${name} ${lastName} ${secondLastName}`
  }
  /**
   * Validate the required fields in modal (dataCollab)
   * @returns {boolean}
   */
  const closeDeleteInactiveModal = () => {
    $('#deleteInactiveModal_2').hide()
    const inputsSearch = document.querySelectorAll('.input-search-leader')
    inputsSearch.forEach(input => {
      //limpiamos los inputs
      document.querySelector(`#${input.id}`).value=""
    })
  }
  /**
   * Delete leaders
   * @returns {boolean}
   */
  const delCollaboratorsLeaders = (e) => {
    e.preventDefault();
    if(dataCollabNewLiders.length === 0){
      return false
    }
    deleteReplaceBoss(dataCollabNewLiders).then((response) => {
      switch (response.code) {
        case 1:
          closeDeleteInactiveModal()
          setLoading(true);
          toastr.success('Los líderes se eliminaron/reemplazaron correctamente.', '¡Exito!');
          getUploadedCollaborators(getJwt("enterprise")).then((response) => {
            if (response.code === 1) {
              // Validar que la plantilla tenga lideres
              validateTemplateBoss(response.listCollab);
              setCollaborators(response.listCollab);
              setLoading(false);
            }
          });
          getUploadedCollaborators(getJwt("enterprise")).then((response) => {
            if (response) {
              if (response.code === 1) {
                // Validar que la plantilla tenga lideres
                validateTemplateBoss(response.listCollab);
                setDescriptions(response.listCollab);
                setSelectedRows([]);
                setSelectedRows_3([]);
                // toastr.success('Se eliminaron correctamente los colaboradores seleccionados.', '¡Éxito!');
              }
            } else {
              setSelectedRows([]);
              setSelectedRows_3([]);
              toastr.error('Ocurrió un error al cargar de nuevo los datos. Intenta nuevamente.', '¡Ooops!');
            }
          });
          break;
        case 99:
          toastr.error("Ocurrió un error inesperado, verifique su conexión a internet.", "Error");
          break;
        case 1000:
          toastr.info("No se envió ningún dato.", "Ojo");
          break;
        case 1001:
          toastr.info("El listado de líderes a eliminar/reemplazar esta vacia.", "Ojo");
          break;
        case 1002:
          toastr.error("Faltan parámetros.", "Ojo");
          break;
        case 1003:
          toastr.error("Algunos parametros son null", "Ojo");
          break;
        case 1004:
          toastr.error("La empresa no existe o no está activa.", "Ojo");
          break;
        default:
          toastr.error("Ocurrió un error inesperado, verifique su conexión a internet.", "Error");
          break;
      }
    })
  }
  /**
   * Validate the required fields in modal (dataCollab)
   * @returns {boolean}
   */
  const validatingFields = () => {
    let check = 0;
    if (dataCollab.dateOfAdmission !== "") {
      if (dataCollab.dateOfAdmission > maxDate || dataCollab.dateOfAdmission < minDate) {
        $("#dateOfAdmission").addClass("border-danger-guay");
        toastr.error('La campo Fecha de ingreso no puede ser mayor a la fecha actual ni menor a ' + minDate + '.', '¡Ooops!');
      } else {
        check = check + 1;
      }
    } else {
      check = check + 1;
    }
    if (dataCollab.cel.length > 0) {
      if (dataCollab.cel.length < 10) {
        $("#cel").addClass("border-danger-guay");
        toastr.error('El campo Teléfono Móvil debe tener 10 caracteres.', '¡Ooops!');
      } else {
        check = check + 1;
      }
    } else {
      // Esta vacio el telefono 
      if(relationBenefitEnterprise){
        // La empresa tiene relacionados beneficios por lo tanto no puede estar vacio
        $("#cel").addClass("border-danger-guay");
        toastr.error('El campo Teléfono Móvil es obligatorio.', '¡Ooops!');
      }else{
        check = check + 1;
      }
    }
    if (dataCollab.namesCollab === "") {
      $("#namesCollab").addClass("border-danger-guay");
      toastr.error('El campo Nombre es obligatorio.', '¡Ooops!');
    } else {
      check = check + 1;
    }
    if (dataCollab.lastName === "") {
      $("#lastName").addClass("border-danger-guay");
      toastr.error('El campo Apellido Paterno es obligatorio.', '¡Ooops!');
    } else {
      check = check + 1;
    }
    if (dataCollab.areaEntId === 0) {
      $("#areaEntId").addClass("border-danger-guay");
      toastr.error('El campo Área es obligatorio.', '¡Ooops!');
    } else {
      check = check + 1;
    }
    if (dataCollab.categoryEntId === 0) {
      $("#categoryEntId").addClass("border-danger-guay");
      toastr.error('El campo Cargo Homologado es obligatorio.', '¡Ooops!');
    } else {
      check = check + 1;
    }
    if (dataCollab.branchEntId === 0) {
      $("#branchEntId").addClass("border-danger-guay");
      toastr.error('El campo Centro de Trabajo es obligatorio.', '¡Ooops!');
    } else {
      check = check + 1;
    }
    if (dataCollab.type_ent_colab === 0) {
      $("#type_ent_colab_l").addClass("text-danger");
      toastr.error('El campo ¿Es Lider? es obligatorio.', '¡Ooops!');
    } else {
      check = check + 1;
    }
    if (rfc.length > 0) {
      if (rfc.length !== 13 && rfc.length !== 10) {
        $("#rfc").addClass("border-danger-guay");
        toastr.error('El campo RFC debe tener 10 o 13 caracteres.', '¡Ooops!');
      } else {
        check = check + 1;
      }
    } else {
      check = check + 1;
    }
    if (email !== "") {
      let regex = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
      if (!regex.test(email)) {
        toastr.error('El campo Correo Electrónico tiene un formato incorrecto.', '¡Ooops!');
      } else { check = check + 1; }
    } else {
      // Esta vacio el correo 
      if(relationBenefitEnterprise){
        // La empresa tiene relacionados beneficios por lo tanto no puede estar vacio
        toastr.error('El campo Correo Electrónico es obligatorio.', '¡Ooops!');
        $("#email").addClass("border-danger-guay");
      }else{
        check = check + 1;
      }
    }
    if (relationBenefitEnterprise) {
      let showMessage = false
      if (dataCollab.curp === '' && !showMessage) {
        toastr.error('El campo CURP es obligatorio.', '¡Ooops!')
        $(`#curp`).addClass("border-danger-guay")
        showMessage = true
      } else {
        if (dataCollab.curp.length !== 18 && !showMessage) {
          toastr.error('El campo CURP debe tener 18 caracteres.', '¡Ooops!')
        $(`#curp`).addClass("border-danger-guay")
          showMessage = true
        }
      }
      if (dataCollab.gender === '' && !showMessage) {
        toastr.error('El campo Género es obligatorio.', '¡Ooops!')
        $(`#gender`).addClass("border-danger-guay")
        showMessage = true
      }
      if (dataCollab.birthdate === '' && !showMessage) {
        toastr.error('El campo Cumpleaños es obligatorio.', '¡Ooops!')
        $(`#birthdate`).addClass("border-danger-guay")
        showMessage = true
      }
      console.log(showMessage)
      if (!showMessage) {
        check = check + 1
      }
    }else{
      check = check + 1
    }
    console.log(check)
    if (check === 11) {
      return true
    } else {
      return false
    }
  }
  const setStep = () => {
    let array = collaborators;
    let correct = true;
    for (let x in array) {
      if (array[x].status === "incorrecto") {
        correct = false
      }
    }
    if (correct) {
      // eslint-disable-next-line
      let regex = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
      /* let regex = /^\w+([\.-]?\w+)*@[a-zA-Z0-9_]+?\.[a-zA-Z0-9_]{2,12}.*$/ */
      let numCol = 0;
      let name = '';
      for (let x in array) {
        if (!regex.test(array[x].email) && array[x].email !== '') {
          numCol = numCol + 1;
          name = array[x].namesCollab + ' ' + array[x].lastName;
        }
      }
      if (numCol !== 0) {
        if (numCol === 1) {
          swal('El correo electrónico del colaborador ' + name + ' es incorrecto.', {
            buttons: {
              cancel: "Cancelar",
              next: "Continuar",
            },
          }).then((value) => {
            switch (value) {
              case "next":
                actualizarSteps(2);
                setCompletedSteps(2);
                break;
              default:
            }
          });
        } else {
          swal('El correo electrónico de ' + numCol + ' colaboradores es incorrecto.', {
            buttons: {
              cancel: "Cancelar",
              next: "Continuar",
            },
          }).then((value) => {
            switch (value) {
              case "next":
                actualizarSteps(2);
                setCompletedSteps(2);
                break;
              default:
            }
          });
        }
      } else {
        actualizarSteps(2);
        setCompletedSteps(2);
      }
    } else {
      toastr.error('Aún quedan colaboradores con información incompleta.', '¡Ooops!');
    }
  }
  /**
   * Function that clean all data in dataCollab
   */
  const cleanDataCollab = () => {
    setDataCollab({
      collaboratorId: 0,
      namesCollab: "",
      lastName: "",
      secondLastName: "",
      email: "",
      cel: "",
      categoryEntId: 0, //Puesto homologado
      jobEntId: 0, //Cargo/Puesto
      areaEntId: 0,
      bossEntId: 0,
      branchEntId: 0,
      levelCollab: 0, // Key player
      type_ent_colab: 0, // Lider
      levelCollabR1: 0, // Key player
      levelCollabR2: 0, // Key player
      type_ent_colabR1: 0, // Lider
      type_ent_colabR2: 0, // Lider
      rfc: "",
      dateOfAdmission: "",
      customA: "",
      customB: "",
      customC: "",
      customD: "",
      customE: "",
      customF: "",
      customG: "",
      curp: "",
      gender: "",
      birthdate: "",
    });
    $("#rfc").removeClass("border-danger-guay");
    $("#type_ent_colab_l").removeClass("text-danger");
    $("#branchEntId").removeClass("border-danger-guay");
    $("#categoryEntId").removeClass("border-danger-guay");
    $("#areaEntId").removeClass("border-danger-guay");
    $("#lastName").removeClass("border-danger-guay");
    $("#namesCollab").removeClass("border-danger-guay");
  }
  /**
   * Function that set all the description in the BootstrapTable.
   * This use some array lists to compare data and replace some values.
   * @returns {array}
   */
  const setDescriptions = (data) => {
    let array = [];
    let array_2 = [];
    let array_3 = [];
    let array_4 = data;
    for (let x in array_4) {
      if (array_4[x].active === 0) {
        array_3.push(array_4[x]);
      } else {
        array.push(array_4[x]);
      }
    }
    for (let x in array) {
      if (array[x].namesCollab === "" || array[x].lastName === "" || array[x].areaEntId === 0 || array[x].categoryEntId === 0 || array[x].branchEntId === 0) {
        array_2.push({ ...array[x], status: 'incorrecto' });
      } else {
        if (array[x].active === 2) {
          array_2.push({ ...array[x], status: 'inactivo' });
        } else {
          array_2.push({ ...array[x], status: 'correcto' });
        }
      }
    }
    for (let a in listBranches) {
      for (let x in array_2) {
        if (listBranches[a].branchEntId === array_2[x].branchEntId) {
          array_2[x].branchEntId = listBranches[a].description;
        }
      }
    }
    for (let b in listBosses) {
      for (let x in array_2) {
        if (listBosses[b].bossEntId === array_2[x].bossEntId) {
          array_2[x].bossEntId = listBosses[b].description;
        }
      }
    }
    for (let c in listAreas) {
      for (let x in array_2) {
        if (listAreas[c].areaId === array_2[x].areaEntId) {
          array_2[x].areaEntId = listAreas[c].description;
        }
      }
    }
    for (let d in listCategories) {
      for (let x in array_2) {
        if (listCategories[d].categoryEntId === array_2[x].categoryEntId) {
          array_2[x].categoryEntId = listCategories[d].description;
        }
      }
    }
    for (let a in listBranches) {
      for (let x in array_3) {
        if (listBranches[a].branchEntId === array_3[x].branchEntId) {
          array_3[x].branchEntId = listBranches[a].description;
        }
      }
    }
    for (let b in listBosses) {
      for (let x in array_3) {
        if (listBosses[b].bossEntId === array_3[x].bossEntId) {
          array_3[x].bossEntId = listBosses[b].description;
        }
      }
    }
    for (let c in listAreas) {
      for (let x in array_3) {
        if (listAreas[c].areaId === array_3[x].areaEntId) {
          array_3[x].areaEntId = listAreas[c].description;
        }
      }
    }
    for (let d in listCategories) {
      for (let x in array_3) {
        if (listCategories[d].categoryEntId === array_3[x].categoryEntId) {
          array_3[x].categoryEntId = listCategories[d].description;
        }
      }
    }
    setCollaborators(array_2);
    setListDeleteCollaborators(array_3);
  }

  const openModal = (modal) => {
    switch (modal) {
      case 1:
        $("#dataCollab").show();
        $("#confirmation").hide();
        break;
      case 2:
        $("#editCollab").show();
        break;
      default:
        break;
    }
  }
  const closeModal = (modal) => {
    switch (modal) {
      case 1:
        $("#confirmation").hide();
        break;
      case 2:
        cleanDataCollab();
        $("#dataCollab").hide();
        $("#confirmation").show();
        break;
      case 3:
        setIndividualDescription(dataCollabAux);
        cleanDataCollab();
        $("#editCollab").hide();
        break;
      default:
        break;
    }
  }
  const rowStyleFormat = () => {
    return { backgroundColor: '#fff' };
  }
  const tdStyleFormat = () => {
    return { padding: '10px', whiteSpace: 'normal', wordWrap: 'break-word', height: '50px', borderRight: 'none', borderLeft: 'none', borderTop: '1px solid #CCD1D1', borderBottom: '1px solid #CCD1D1' };
  }
  const thStyleFormat = () => {
    return { backgroundColor: '#D7DBDD', border: 'none', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '0px', paddingRight: '0px' };
  }
  /**
    * Individual props of search input of the table
    * @returns
    */
  const createCustomSearchField = () => {
    return (
      <SearchField
        className='my-custom-class'
        placeholder='Buscar' />
    );
  }
  /**
    * Structure of the toll bar in the table
    * @param props 
    * @returns 
    */
  const createCustomToolBar = props => {
    return (
      <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 searcher-table mt-1' style={{}}>
        <div className="row">
          <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 d-flex flex-wrap align-content-center font-medium-2">
            <span className="text-base">Colaboradores: <span className="text-base-guay font-weight-bold">{" " + numCollabs}</span></span>
            <span className="ml-4 text-base">Áreas: <span className="text-base-guay font-weight-bold">{" " + numAreas}</span></span>
            <span className="ml-4 text-base">Centros de trabajo: <span className="text-base-guay font-weight-bold">{" " + numBranches}</span></span>
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            {props.components.searchPanel}
          </div>
        </div>
      </div>
    );
  }
  /**
    * Table props
    */
  const options = {
    sizePerPage: 10,  // Showing 10 for the size per page as default
    hideSizePerPage: true,
    noDataText: 'No se encontraron registros.',
    searchField: createCustomSearchField,
    toolBar: createCustomToolBar,
  };
  const handleRowSelect = (row, isSelected, e) => {
    if (isSelected) {
      setSelectedRows([
        ...selectedRows,
        row.collaboratorId
      ]);
      setSelectedRows_3([
        ...selectedRows_3,
        row
      ]);
    } else {
      let filtedSelectedRows = selectedRows.filter(function (element) {
        return element !== row.collaboratorId
      });
      let filtedSelectedRows_2 = selectedRows_3.filter(function (element) {
        return element.collaboratorId !== row.collaboratorId
      });
      setSelectedRows(filtedSelectedRows);
      setSelectedRows_3(filtedSelectedRows_2);
    }
  }
  const handleSelectAll = (isSelected, rows) => {
    if (isSelected) {
      let array = [];
      for (let x in rows) {
        array.push(rows[x].collaboratorId);
      }
      setSelectedRows(array);
    } else {
      setSelectedRows([]);
    }
  }
  /**
   * Checkbox row props
   */
  const selectRow = {
    mode: 'checkbox',  // multi select
    columnWidth: '60px',
    selected: selectedRows,
    onSelect: handleRowSelect,
    onSelectAll: handleSelectAll
  };
  /**
   * Function that delete/inactive multiple selected collaborators
   * @param {int} option - Set the collaborators status (0 - delete, 1 - inactive, 2 - active, 3 - undelete)
   */
  const handleCollaboratorStatus = (option) => {
    let ids = "";
    switch (option) {
      case 0:
        for (let x in selectedRows) {
          ids = ids + selectedRows[x] + ",";
        }
        delListCollaborators(getJwt("enterprise"), option, ids.slice(0, -1)).then((response) => {
          if (response) {
            if (response.code === 1) {
              getUploadedCollaborators(getJwt("enterprise")).then((response) => {
                if (response) {
                  if (response.code === 1) {
                    // Validar que la plantilla tenga lideres
                    validateTemplateBoss(response.listCollab);
                    setDescriptions(response.listCollab);
                    setSelectedRows([]);
                    setSelectedRows_3([]);
                    $('#deleteInactiveModal').hide();
                    toastr.success('Se eliminaron correctamente los colaboradores seleccionados.', '¡Éxito!');
                  }
                } else {
                  setSelectedRows([]);
                  setSelectedRows_3([]);
                  $('#deleteInactiveModal').hide();
                  toastr.error('Ocurrió un error con la respusta del servidor. Intenta nuevamente.', '¡Ooops!');
                }
              });
            } else {
              if (response.code === 1001) {
                getUploadedCollaborators(getJwt("enterprise")).then((response) => {
                  if (response) {
                    if (response.code === 1) {
                      // Validar que la plantilla tenga lideres
                      validateTemplateBoss(response.listCollab);
                      setDescriptions(response.listCollab);
                    } else {
                      toastr.error('Ocurrió un error al cargar la tabla. Intenta nuevamente.', '¡Ooops!');
                    }
                  } else {
                    toastr.error('Ocurrió un error al cargar la tabla. Intenta nuevamente.', '¡Ooops!');
                  }
                });
                setSelectedRows([]);
                setSelectedRows_3([]);
                setListBosses_2(response.collabJobs);
                $('#deleteInactiveModal').hide();
                $('#deleteInactiveModal_2').show();
                toastr.success('Se eliminaron correctamente los colaboradores seleccionados.', '¡Éxito!');
              } else {
                setSelectedRows([]);
                setSelectedRows_3([]);
                $('#deleteInactiveModal').hide();
                toastr.error('Ocurrió un error con la respusta del servidor. Intenta nuevamente.', '¡Ooops!');
              }
            }
          } else {
            setSelectedRows([]);
            setSelectedRows_3([]);
            $('#deleteInactiveModal').hide();
            toastr.error('Ocurrió un error con la respusta del servidor. Intenta nuevamente.', '¡Ooops!');
          }
        });
        break;
      case 1:
        for (let x in selectedRows) {
          ids = ids + selectedRows[x] + ",";
        }
        delListCollaborators(getJwt("enterprise"), option, ids.slice(0, -1)).then((response) => {
          if (response) {
            if (response.code === 1) {
              getUploadedCollaborators(getJwt("enterprise")).then((response) => {
                if (response) {
                  if (response.code === 1) {
                    // Validar que la plantilla tenga lideres
                    validateTemplateBoss(response.listCollab);
                    setDescriptions(response.listCollab);
                    setSelectedRows([]);
                    setSelectedRows_3([]);
                    $('#deleteInactiveModal').hide();
                    toastr.success('Se inactivaron correctamente los colaboradores seleccionados.', '¡Éxito!');
                  }
                } else {
                  setSelectedRows([]);
                  setSelectedRows_3([]);
                  $('#deleteInactiveModal').hide();
                  toastr.error('Ocurrió un error con la respusta del servidor. Intenta nuevamente.', '¡Ooops!');
                }
              });
            } else {
              if (response.code === 1001) {
                getUploadedCollaborators(getJwt("enterprise")).then((response) => {
                  if (response) {
                    if (response.code === 1) {
                      // Validar que la plantilla tenga lideres
                      validateTemplateBoss(response.listCollab);
                      setDescriptions(response.listCollab);
                    } else {
                      toastr.error('Ocurrió un error al cargar la tabla. Intenta nuevamente.', '¡Ooops!');
                    }
                  } else {
                    toastr.error('Ocurrió un error al cargar la tabla. Intenta nuevamente.', '¡Ooops!');
                  }
                });
                setSelectedRows([]);
                setSelectedRows_3([]);
                setListBosses_2(response.collabJobs);
                $('#deleteInactiveModal').hide();
                $('#deleteInactiveModal_2').show();
                toastr.success('Se inactivaron correctamente los colaboradores seleccionados.', '¡Éxito!');
              } else {
                setSelectedRows([]);
                setSelectedRows_3([]);
                $('#deleteInactiveModal').hide();
                toastr.error('Ocurrió un error con la respusta del servidor. Intenta nuevamente.', '¡Ooops!');
              }
            }
          } else {
            setSelectedRows([]);
            setSelectedRows_3([]);
            $('#deleteInactiveModal').hide();
            toastr.error('Ocurrió un error con la respusta del servidor. Intenta nuevamente.', '¡Ooops!');
          }
        });
        break;
      case 2:
        for (let x in selectedRows) {
          ids = ids + selectedRows[x] + ",";
        }
        delListCollaborators(getJwt("enterprise"), option, ids.slice(0, -1)).then((response) => {
          if (response) {
            if (response.code === 1) {
              getUploadedCollaborators(getJwt("enterprise")).then((response) => {
                if (response) {
                  if (response.code === 1) {
                    // Validar que la plantilla tenga lideres
                    validateTemplateBoss(response.listCollab);
                    setDescriptions(response.listCollab);
                    setSelectedRows([]);
                    setSelectedRows_3([]);
                    $('#deleteInactiveModal').hide();
                    toastr.success('Se activaron correctamente los colaboradores seleccionados.', '¡Éxito!');
                  }
                } else {
                  setSelectedRows([]);
                  setSelectedRows_3([]);
                  $('#deleteInactiveModal').hide();
                  toastr.error('Ocurrió un error con la respusta del servidor. Intenta nuevamente.', '¡Ooops!');
                }
              });
            } else {
              setSelectedRows([]);
              setSelectedRows_3([]);
              $('#deleteInactiveModal').hide();
              toastr.error('Ocurrió un error con la respusta del servidor. Intenta nuevamente.', '¡Ooops!');
            }
          } else {
            setSelectedRows([]);
            setSelectedRows_3([]);
            $('#deleteInactiveModal').hide();
            toastr.error('Ocurrió un error con la respusta del servidor. Intenta nuevamente.', '¡Ooops!');
          }
        });
        break;
      case 3:
        for (let x in selectedRows_2) {
          ids = ids + selectedRows_2[x] + ",";
        }
        delListCollaborators(getJwt("enterprise"), option, ids.slice(0, -1)).then((response) => {
          if (response) {
            if (response.code === 1) {
              getUploadedCollaborators(getJwt("enterprise")).then((response) => {
                if (response) {
                  if (response.code === 1) {
                    // Validar que la plantilla tenga lideres
                    validateTemplateBoss(response.listCollab);
                    setDescriptions(response.listCollab);
                    setSelectedRows_2([]);
                    setShowHistory(0);
                    toastr.success('Se reactivaron correctamente los colaboradores seleccionados.', '¡Éxito!');
                  }
                } else {
                  setSelectedRows_2([]);
                  setShowHistory(0);
                  toastr.error('Ocurrió un error con la respusta del servidor. Intenta nuevamente.', '¡Ooops!');
                }
              });
            } else {
              setSelectedRows_2([]);
              setShowHistory(0);
              toastr.error('Ocurrió un error con la respusta del servidor. Intenta nuevamente.', '¡Ooops!');
            }
          } else {
            setSelectedRows_2([]);
            setShowHistory(0);
            toastr.error('Ocurrió un error con la respusta del servidor. Intenta nuevamente.', '¡Ooops!');
          }
        });
        break;
      default:
        toastr.error('Ocurrió un error con la respusta del servidor. Intenta nuevamente.', '¡Ooops!');
        break;
    }
  }
  /**
    * Individual props of search input of the table
    * @returns
    */
  const createCustomSearchField_2 = () => {
    return (
      <SearchField
        className='my-custom-class'
        placeholder='Buscar' />
    );
  }
  /**
    * Structure of the toll bar in the table
    * @param props 
    * @returns 
    */
  const createCustomToolBar_2 = props => {
    return (
      <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 searcher-table mt-1' style={{}}>
        <div className="row">
          <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 d-flex flex-wrap align-content-center font-medium-2">
            <span className="text-base">Colaboradores: <span className="text-base-guay font-weight-bold">{" " + listDeleteCollaborators.length}</span></span>
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            {props.components.searchPanel}
          </div>
        </div>
      </div>
    );
  }
  /**
    * Table props
    */
  const options_2 = {
    sizePerPage: 10,  // Showing 10 for the size per page as default
    hideSizePerPage: true,
    noDataText: 'No se encontraron registros.',
    searchField: createCustomSearchField_2,
    toolBar: createCustomToolBar_2,
  };
  const handleRowSelect_2 = (row, isSelected, e) => {
    if (isSelected) {
      setSelectedRows_2([
        ...selectedRows_2,
        row.collaboratorId
      ]);
    } else {
      let filtedSelectedRows = selectedRows_2.filter(function (element) {
        return element !== row.collaboratorId
      });
      setSelectedRows_2(filtedSelectedRows);
    }
  }
  const handleSelectAll_2 = (isSelected, rows) => {
    if (isSelected) {
      let array = [];
      for (let x in rows) {
        array.push(rows[x].collaboratorId);
      }
      setSelectedRows_2(array);
    } else {
      setSelectedRows_2([]);
    }
  }
  /**
   * Checkbox row props
   */
  const selectRow_2 = {
    mode: 'checkbox',  // multi select
    columnWidth: '4%',
    selected: selectedRows_2,
    onSelect: handleRowSelect_2,
    onSelectAll: handleSelectAll_2
  }
  const setButton = () => {
    let active = true;
    for (let x in selectedRows_3) {
      if (selectedRows_3[x].status === "inactivo") {
        active = false
      }
    }
    if (active) {
      return (
        <button
          className="btn btn-pink btn-sm w-25 pointer float-right"
          onClick={() => handleCollaboratorStatus(1)}>Inactivar</button>
      )
    } else {
      return (
        <button
          className="btn btn-pink btn-sm w-25 pointer float-right"
          onClick={() => handleCollaboratorStatus(2)}>Reactivar</button>
      )
    }
  }
  /**
   * 
   * @param {*} e 
   */
  const handleCheckboxChange = e => {
    let newArr = []
    filterColumnsCat.forEach(data => {
      if (data.name === e.target.name) {
        newArr.push({
          ...data,
          checked: !data.checked,
        })
      } else {
        newArr.push(data)
      }
    })
    setFilterColumnsCat(newArr)
  }

  const setBirthdate = (cell, row, enumObject, rowIndex) => {
    if (cell === '' || cell === undefined) {
      return ''
    } else {
      let getDate = cell.split(' ')
      return getDate[0]
    }
  }

  const setGender = (cell, row, enumObject, rowIndex) => {
    if (cell === '' || cell === undefined) {
      return ''
    } else {
      const getGender = genderCat.filter(gender => gender.idGender === cell)
      return getGender[0].description
    }
  }

  if (loading) {
    return (
      <LoaderApp />
    )
  } else {
    return (
      showHistory === 0
        ?
        (
          <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-white-guay rounded-top mb-5">
            <div className="row">
              <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                <div className="row">
                  <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-start flex-wrap align-content-center">
                    <span className="font-medium-2">Confirma tus colaboradores</span>
                  </div>
                  <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-end flex-wrap align-content-center">
                    {selectedRows.length > 0
                      ?
                      (
                        <div
                          className="pointer d-flex flex-wrap align-content-center mr-1"
                          onClick={() => $('#deleteInactiveModal').show()}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Eliminar colaborador">
                          <i className='ft ft-trash-2 text-blue pointer' style={{ fontSize: '25px' }} />
                        </div>
                      )
                      :
                      (null)
                    }
                    {listDeleteCollaborators.length > 0
                      ?
                      (
                        <div
                          className="pointer d-flex flex-wrap align-content-center mr-1"
                          onClick={() => setShowHistory(1)}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Historial de colaborador">
                          <i className='ft ft-clock text-blue pointer' style={{ fontSize: '25px' }} />
                        </div>
                      )
                      :
                      (null)
                    }
                    {
                      !justRead && (
                        <div className="pointer" onClick={() => setShowHistory(2)} >
                          <img
                            className="mr-1"
                            src="./../img/collabImages/iconos_carga_colaboradores-07.png"
                            alt="img-guay"
                            style={{ height: '25px', width: '25px' }}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Agregar colaborador" />
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="col-12 mx-auto mb-2">
                <BootstrapTable
                  data={collaborators}
                  condensed
                  search
                  pagination={true}
                  tableStyle={{ border: 'none', padding: '0px', fontSize: '12px' }}
                  trClassName='tr-data-table table-checkbox-2'
                  options={options}
                  tableHeaderClass='table-checkbox-2'
                  searchPlaceholder='Buscar...'
                  trStyle={rowStyleFormat}
                  selectRow={justRead ? {} : selectRow}
                >
                  <TableHeaderColumn dataField="collaboratorId" isKey dataAlign="center" dataFormat={setIcon1} tdStyle={tdStyleFormat} width="50px" thStyle={thStyleFormat()} hidden={justRead} ></TableHeaderColumn>
                  <TableHeaderColumn dataField="collaboratorId" dataAlign="center" dataFormat={setIcon2} tdStyle={tdStyleFormat} width="50px" thStyle={thStyleFormat()} hidden={justRead} ></TableHeaderColumn>
                  <TableHeaderColumn dataField="status" dataAlign="center" dataFormat={setDataStatus} tdStyle={tdStyleFormat} dataSort width="50px" thStyle={thStyleFormat()}>Estado</TableHeaderColumn>
                  <TableHeaderColumn dataField="namesCollab" width="175px" dataAlign="center" dataFormat={setData} tdStyle={tdStyleFormat} thStyle={thStyleFormat()} dataSort >{"Nombre(s)"}</TableHeaderColumn>
                  <TableHeaderColumn dataField="lastName" width="175px" dataAlign="center" dataFormat={setData} tdStyle={tdStyleFormat} thStyle={thStyleFormat()} dataSort >Apellidos</TableHeaderColumn>
                  <TableHeaderColumn dataField="email" width="200px" dataAlign="center" tdStyle={tdStyleFormat} dataFormat={setEmail} thStyle={thStyleFormat()} >Email</TableHeaderColumn>
                  <TableHeaderColumn dataField="categoryEntId" width="175px" dataAlign="center" dataFormat={setCategoryEntId} tdStyle={tdStyleFormat} thStyle={thStyleFormat()} hidden={filterColumnsCat[0].checked} dataSort >Cargo</TableHeaderColumn>
                  <TableHeaderColumn dataField="areaEntId" width="175px" dataAlign="center" dataFormat={setAreaEntId} tdStyle={tdStyleFormat} thStyle={thStyleFormat()} hidden={filterColumnsCat[1].checked} dataSort >Área</TableHeaderColumn>
                  <TableHeaderColumn dataField="branchEntId" width="175px" dataAlign="center" dataFormat={setBranchEntId} tdStyle={tdStyleFormat} thStyle={thStyleFormat()} hidden={filterColumnsCat[2].checked} dataSort >Centro de trabajo</TableHeaderColumn>
                  <TableHeaderColumn dataField="customA" width="100px" dataAlign="center" dataFormat={setTeleasistencia} tdStyle={tdStyleFormat} thStyle={thStyleFormat()} hidden={filterColumnsCat[3].checked} dataSort >Teleasistencia</TableHeaderColumn>
                  <TableHeaderColumn dataField="curp" width="100px" dataAlign="center" tdStyle={tdStyleFormat} thStyle={thStyleFormat()} hidden={filterColumnsCat[4].checked} dataSort >CURP</TableHeaderColumn>
                  <TableHeaderColumn dataField="gender" width="100px" dataAlign="center" dataFormat={setGender} tdStyle={tdStyleFormat} thStyle={thStyleFormat()} hidden={filterColumnsCat[5].checked} dataSort >Género</TableHeaderColumn>
                  <TableHeaderColumn dataField="birthdate" width="125px" dataAlign="center" dataFormat={setBirthdate} tdStyle={tdStyleFormat} thStyle={thStyleFormat()} hidden={filterColumnsCat[6].checked} dataSort >Fecha de nacimiento</TableHeaderColumn>
                  <TableHeaderColumn dataField="rfc" width="100px" dataAlign="center" tdStyle={tdStyleFormat} thStyle={thStyleFormat()} hidden={filterColumnsCat[7].checked} dataSort >RFC</TableHeaderColumn>
                </BootstrapTable>
                <div style={{
                  position: 'absolute',
                  top: '70px',
                  right: '0'
                }}>
                  <FilterColumn
                    handleCheckboxChange={handleCheckboxChange}
                    catalogFilters={filterColumnsCat}
                  />
                </div>
              </div>
              <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mb-2">
                {
                  !justRead && <button className="btn btn-pink btn-sm pointer float-right" onClick={() => setStep()} >Continuar</button>
                }
              </div>
            </div>
            {/*<!-- The Modal -->*/}
            <div
              className="modal"
              id="editCollab"
              style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
              data-backdrop="false">
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div
                  className="modal-content"
                  style={{ borderRadius: '1.5rem', border: '1px solid gray', overflow: 'auto' }}>
                  {/*<!-- Modal body -->*/}
                  <div className="modal-body pb-5 scrollbar-style">
                    <div className="col-lg-10 mx-auto">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-lg-12 d-flex justify-content-center align-items-center">
                            <img
                              src="./../img/imagesAdminUsuarios/iconos_shopy_guay-40.png"
                              alt="img-guay" style={{ height: '50px', width: '50px' }} />
                            <h2 className="align-middle font-weight-bold">Datos del colaborador</h2>
                          </div>
                        </div>
                      </div>
                      <form>
                        <div className="col-12 mt-1">
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label className="text-base">Nombre</label>
                                <input
                                  type="text"
                                  name="namesCollab"
                                  className="form-control"
                                  maxLength="100"
                                  onChange={getValue}
                                  value={namesCollab} />
                              </div>
                              <div className="form-group">
                                <label className="text-base">Apellido paterno</label>
                                <input
                                  type="text"
                                  name="lastName"
                                  className="form-control"
                                  maxLength="100"
                                  onChange={getValue}
                                  value={lastName} />
                              </div>
                              <div className="form-group">
                                <label className="text-base">Apellido materno</label>
                                <input
                                  type="text"
                                  name="secondLastName"
                                  className="form-control"
                                  maxLength="100"
                                  onChange={getValue}
                                  value={secondLastName} />
                              </div>
                              <div className="form-group">
                                <label className="text-base">Correo Electrónico</label>
                                <input
                                  type="email"
                                  name="email"
                                  id="email"
                                  placeholder="_@_._"
                                  className="form-control"
                                  maxLength="100"
                                  onChange={getValue}
                                  value={email} />
                              </div>
                              <div className="form-group">
                                <label className="text-base">Cargo/Puesto</label>
                                <select
                                  className="form-control"
                                  name="jobEntId"
                                  onChange={getValue}
                                  value={jobEntId}>
                                  <option value="0">--Seleccione--</option>
                                  {listJobs.map((optionJobs) => (
                                    <option
                                      key={optionJobs.jobEntId}
                                      value={optionJobs.jobEntId}
                                    >
                                      {optionJobs.description}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="form-group">
                                <label className="text-base">Área</label>
                                <select
                                  className="form-control"
                                  name="areaEntId"
                                  onChange={getValue}
                                  value={areaEntId}>
                                  <option value="0">--Seleccione--</option>
                                  {listAreas.map((optionAreas) => (
                                    <option
                                      key={optionAreas.areaId}
                                      value={optionAreas.areaId}
                                    >
                                      {optionAreas.description}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="form-group">
                                <label className="text-base">Jefe Directo</label>
                                <select
                                  className="form-control"
                                  name="bossEntId"
                                  onChange={getValue}
                                  value={bossEntId}>
                                  <option value="0">--Seleccione--</option>
                                  {listBosses.map((optionBosses) => (
                                    <option
                                      key={optionBosses.bossEntId}
                                      value={optionBosses.bossEntId}
                                    >
                                      {optionBosses.description}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="form-group">
                                <label className="text-base">Cargo Homologado</label>
                                <select
                                  className="form-control"
                                  name="categoryEntId"
                                  onChange={getValue}
                                  value={categoryEntId}>
                                  <option value="0">--Seleccione--</option>
                                  {listCategories.map((optionCategories) => (
                                    <option
                                      key={optionCategories.categoryEntId}
                                      value={optionCategories.categoryEntId}
                                    >
                                      {optionCategories.description}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="form-group">
                                <label className="text-base">Centro de Trabajo</label>
                                <select
                                  className="form-control"
                                  name="branchEntId"
                                  onChange={getValue}
                                  value={branchEntId}>
                                  <option value="0">--Seleccione--</option>
                                  {listBranches.map((optionBranches) => (
                                    <option
                                      key={optionBranches.branchEntId}
                                      value={optionBranches.branchEntId}
                                    >
                                      {optionBranches.description}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="form-group">
                                <label
                                  className="text-base"
                                  id="type_ent_colab_l">¿Es Lider?</label>
                                <div>
                                  <div className="form-check-inline">
                                    <label className="form-check-label">
                                      <input
                                        type="radio"
                                        onChange={getValue}
                                        className="form-check-input"
                                        name="type_ent_colab"
                                        value={1}
                                        checked={type_ent_colabR1} />Si
                                    </label>
                                  </div>
                                  <div className="form-check-inline">
                                    <label className="form-check-label">
                                      <input
                                        type="radio"
                                        onChange={getValue}
                                        className="form-check-input"
                                        name="type_ent_colab"
                                        value={2}
                                        checked={type_ent_colabR2} />No
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="text-base">RFC</label>
                                <input
                                  type="text"
                                  name="rfc"
                                  id="rfc"
                                  className="form-control"
                                  onChange={getValue}
                                  value={rfc} />
                              </div>
                              <div className="form-group">
                                <label className="text-base">Teléfono Móvil</label>
                                <input
                                  type="number"
                                  name="cel"
                                  id="cel"
                                  className="form-control"
                                  onChange={getValue}
                                  value={cel}
                                  onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} />
                              </div>
                              <div className="form-group">
                                <label className="text-base" id>Key Player</label>
                                <div>
                                  <div className="form-check-inline">
                                    <label className="form-check-label" >
                                      <input
                                        type="radio"
                                        onChange={getValue}
                                        className="form-check-input"
                                        name="levelCollab"
                                        value={1}
                                        checked={levelCollabR1} />Si
                                    </label>
                                  </div>
                                  <div className="form-check-inline">
                                    <label className="form-check-label" >
                                      <input
                                        type="radio"
                                        onChange={getValue}
                                        className="form-check-input"
                                        name="levelCollab"
                                        value={2}
                                        checked={levelCollabR2} />No
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="text-base">Fecha de ingreso</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="dd/mm/aaaa"
                                  min={minDate}
                                  max={maxDate}
                                  name="dateOfAdmission"
                                  id="dateOfAdmission"
                                  onChange={getValue}
                                  value={dateOfAdmission} />
                              </div>
                              <div className="form-group">
                                <label className="text-base">Telemedicina</label>
                                <div>
                                  <div className="form-check-inline">
                                    <label className="form-check-label" >
                                      <input
                                        type="radio"
                                        onChange={getValue}
                                        className="form-check-input"
                                        name="customA"
                                        value="1"
                                        checked={customAR1} />Si
                                    </label>
                                  </div>
                                  <div className="form-check-inline">
                                    <label className="form-check-label" >
                                      <input
                                        type="radio"
                                        onChange={getValue}
                                        className="form-check-input"
                                        name="customA"
                                        value="2"
                                        checked={customAR2} />No
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div id="accordionUpdate">
                                <div className="card">
                                  <div className="card-header">
                                    <a className="card-link" data-toggle="collapse" href="#collapseUpdate">Abrir campos personalizados</a>
                                  </div>
                                  <div className="collapse" id="collapseUpdate" data-parent="#accordionUpdate">
                                    <div className="card-body">
                                      {/* <div className="form-group">
                                        <label className="text-base">Campo personalizado 1 <span className="text-base">{"(máx. 100 caracteres)"}</span></label>
                                        <textarea
                                          className="form-control textarea-unresize"
                                          rows="5"
                                          maxLength="100"
                                          name="customA"
                                          onChange={getValue}
                                          value={customA}></textarea>
                                      </div> */}
                                      <div className="form-group">
                                        <label className="text-base">Campo personalizado 1 <span className="text-base">{"(máx. 100 caracteres)"}</span></label>
                                        <textarea
                                          className="form-control textarea-unresize"
                                          rows="5"
                                          maxLength="100"
                                          name="customB"
                                          onChange={getValue}
                                          value={customB}></textarea>
                                      </div>
                                      <div className="form-group">
                                        <label className="text-base">Campo personalizado 2 <span className="text-base">{"(máx. 100 caracteres)"}</span></label>
                                        <textarea
                                          className="form-control textarea-unresize"
                                          rows="5"
                                          maxLength="100"
                                          name="customC"
                                          onChange={getValue}
                                          value={customC}></textarea>
                                      </div>
                                      <div className="form-group">
                                        <label className="text-base">Campo personalizado 3 <span className="text-base">{"(máx. 100 caracteres)"}</span></label>
                                        <textarea
                                          className="form-control textarea-unresize"
                                          rows="5"
                                          maxLength="100"
                                          name="customD"
                                          onChange={getValue}
                                          value={customD}></textarea>
                                      </div>
                                      <div className="form-group">
                                        <label className="text-base">Campo personalizado 4 <span className="text-base">{"(máx. 100 caracteres)"}</span></label>
                                        <textarea
                                          className="form-control textarea-unresize"
                                          rows="5"
                                          maxLength="100"
                                          name="customE"
                                          onChange={getValue}
                                          value={customE}></textarea>
                                      </div>
                                      <div className="form-group">
                                        <label className="text-base">Campo personalizado 5 <span className="text-base">{"(máx. 100 caracteres)"}</span></label>
                                        <textarea
                                          className="form-control textarea-unresize"
                                          rows="5"
                                          maxLength="100"
                                          name="customF"
                                          onChange={getValue}
                                          value={customF}></textarea>
                                      </div>
                                      <div className="form-group">
                                        <label className="text-base">Campo personalizado 6 <span className="text-base">{"(máx. 100 caracteres)"}</span></label>
                                        <textarea
                                          className="form-control textarea-unresize"
                                          rows="5"
                                          maxLength="100"
                                          name="customG"
                                          onChange={getValue}
                                          value={customG}></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <DentalForm
                                getValue={getValue}
                                dataCollab={dataCollab}
                                genderCat={genderCat}
                                relationBenefitEnterprise={relationBenefitEnterprise}
                                getFormateDate={getFormateDate}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className="col-12">
                        <button
                          className="btn btn-blue-3 btn-sm pl-5 pr-5 pointer float-left"
                          onClick={() => closeModal(3)}>Atrás</button>
                        <button
                          className="btn btn-pink btn-sm pl-5 pr-5 pointer float-right"
                          onClick={() => updateCollaborator()}>Guardar</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="modal" id="confirmDeleteCollab" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content" style={{ borderRadius: '1.5rem', border: '1px solid gray', overflow: 'auto' }}>
                  
                  <div className="modal-body scrollbar-style">
                    <div className="row d-flex justify-content-center mb-3">
                      <div className="col-8">
                        <div className="col-12 text-center my-2">
                          <i className="ft-alert-circle icon-bigXL-size text-yellow" />
                        </div>
                        <div className="col-12 text-center mb-5">
                          <p className="font-medium-2 m-1">Estas seguro de eliminar a:</p>
                          <p className="font-medium-2 font-weight-bold m-0">{deleteCollabData.name ? deleteCollabData.name : '--'} {deleteCollabData.lastName ? deleteCollabData.lastName : '--'}, con puesto {deleteCollabData.job ? deleteCollabData.job : "--"} del area de {deleteCollabData.area ? deleteCollabData.area : "--"}</p>
                        </div>
                        <div className="row mt-5">
                          <div className="col-6 d-flex justify-content-start">
                            <button className="btn btn-blue-3 btn-sm pl-5 pr-5 pointer" onClick={() => $('#confirmDeleteCollab').hide()}>No</button>
                          </div>
                          <div className="col-6 d-flex justify-content-end">
                            <button className="btn btn-pink btn-sm pl-5 pr-5 pointer" onClick={() => swalDeleteCollaborator(deleteCollabData)}>Si</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/*<!-- The Modal -->*/}
            <div
              className="modal"
              id="deleteInactiveModal"
              style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
              data-backdrop="false">
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div
                  className="modal-content"
                  style={{
                    borderRadius: '1.5rem',
                    border: '1px solid gray',
                    overflow: 'auto'
                  }}>
                  {/*<!-- Modal body -->*/}
                  <div className="modal-body scrollbar-style">
                    <div className="row d-flex justify-content-center mb-3">
                      <div className="col-10">
                        <div className="col-lg-12 d-flex justify-content-center mt-2">
                          <img src="./../img/collabImages/iconos_carga_colaboradores-11.png" alt="img-guay" style={{ height: '100px', width: '100px' }} />
                        </div>
                        <div className="col-12 mt-2 text-center font-medium-2">
                          <p>Seleccionaste <span className="text-base-guay">{selectedRows.length}</span> colaboradores ¿qué deseas hacer?</p>
                        </div>
                        <div className="col-12 mt-2 d-flex justify-content-end">
                          <button
                            className="btn btn-pink w-25 btn-sm pointer"
                            onClick={() => handleCollaboratorStatus(0)}>Eliminar</button>
                        </div>
                        <div className="col-12 mt-1">
                          <button
                            className="btn btn-blue-3 w-25 btn-sm pointer float-left"
                            onClick={() => $('#deleteInactiveModal').hide()}>Regresar</button>
                          {setButton()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- The Modal -->*/}
            {/*<!-- The Modal -->*/}
            <div
              className="modal"
              id="deleteInactiveModal_2"
              style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
              data-backdrop="false">
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div
                  className="modal-content"
                  style={{
                    borderRadius: '1.5rem',
                    border: '1px solid gray',
                    overflow: 'auto'
                  }}>
                  {/*<!-- Modal body -->*/}
                  <div className="modal-body scrollbar-style">
                    <div className="row d-flex justify-content-center mb-3">
                      <div className="col-10">
                        <div className="col-lg-12 d-flex justify-content-center mt-2">
                          <img src="./../img/collabImages/iconos_carga_colaboradores-12.png" alt="img-guay" style={{ height: '100px', width: '100px' }} />
                        </div>
                        <div className="col-12 mt-1 text-center font-medium-2">
                          <p>Se eliminaron correctamente los colaboradores con excepción de los siguientes:</p>
                        </div>
                        <div className="col-12 mt-1 text-center font-medium-2">
                              <div className='row'>
                                <div className="col-6 mt-1 text-center font-medium-2">
                                  <span className="d-block text-center font-weight-bold">Eliminar</span>
                                </div> 
                                <div className="col-6 mt-1 text-center font-medium-2">
                                  <span className="d-block text-center font-weight-bold">Reemplazar por</span>
                                </div> 
                              </div> 
                              {listBosses_2.map((data) =>
                                <div className='row'>
                                  <div className="col-6 mt-1 text-center font-medium-2">
                                    <span className="d-block text-base-guay text-center">{data.namesCollab + " " + data.lastName}</span>
                                  </div> 
                                  <div className="col-6 mt-1 text-center font-medium-2">  
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            maxLength="100"
                                            className="form-control input-search-leader"
                                            placeholder="Nombre del nuevo líder"
                                            id={`collaborator-${data.collaboratorId}`}
                                            name={`collaborator-${data.collaboratorId}`}
                                            autoComplete="off"
                                            onChange={getCollaborator}
                                            // value={''}
                                             />
                                        <div className="shadow-card-1 rounded select-option-style-1" key={`div-collaborator-${data.collaboratorId}`} id={`div-collaborator-${data.collaboratorId}`} hidden={true}>
                                            {collaboratorsList.map((collaboratorsList) => (
                                                <span
                                                    key={collaboratorsList.collaboratorId}
                                                    value={collaboratorsList.collaboratorId}
                                                    id={data.collaboratorId}
                                                    className="d-block p-1 select-option-style-2 pointer"
                                                    onClick={(e) => selectCollaborator(e, collaboratorsList.namesCollab, collaboratorsList.lastName, collaboratorsList.secondLastName,collaboratorsList.collaboratorId,data.collaboratorId)}
                                                >
                                                    {collaboratorsList.namesCollab + " " + collaboratorsList.lastName + " " + collaboratorsList.secondLastName}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                        </div>
                        <div className="col-12 mt-2 d-flex justify-content-center">
                          <button
                            className="btn btn-small btn-cancel-style2 mr-3"
                            onClick={() => closeDeleteInactiveModal()}>Cerrar</button>
                          <button
                            className="btn btn-small btn-save-style2 ml-3"
                            onClick={(e) => delCollaboratorsLeaders(e)}>Eliminar Líderes</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- The Modal -->*/}
          </div >
        )
        :
        (
          showHistory === 1
            ?
            (
              <HistoryCollab
                listDeleteCollaborators={listDeleteCollaborators}
                options_2={options_2}
                selectedRows_2={selectedRows_2}
                selectRow_2={selectRow_2}
                rowStyleFormat={rowStyleFormat}
                setDataStatus_2={setDataStatus_2}
                tdStyleFormat={tdStyleFormat}
                thStyleFormat={thStyleFormat}
                setData={setData}
                setBranchEntId={setBranchEntId}
                setCategoryEntId={setCategoryEntId}
                setAreaEntId={setAreaEntId}
                setTeleasistencia={setTeleasistencia}
                handleCollaboratorStatus={handleCollaboratorStatus}
                setShowHistory={setShowHistory}
                justRead={justRead}
              />
            )
            :
            (
              <ModalUploadCollab
                setLoading={setLoading}
                setShowHistory={setShowHistory}
                getValue={getValue}
                setNewCollab={setNewCollab}
                listJobs={listJobs}
                listBosses={listBosses}
                setListBosses={setListBosses}
                listCategories={listCategories}
                listAreas={listAreas}
                listBranches={listBranches}
                setListAreas={setListAreas}
                setListBranches={setListBranches}
                numCollabs={numCollabs}
                numAreas={numAreas}
                numBranches={numBranches}
                dataCollab={dataCollab}
                setDescriptions={setDescriptions}
                currentNumCollabs={currentNumCollabs}
                setCurrentNumCollabs={setCurrentNumCollabs}
                currentNumAreas={currentNumAreas}
                setCurrentNumAreas={setCurrentNumAreas}
                currentNumBranches={currentNumBranches}
                setCurrentNumBranches={setCurrentNumBranches}
                minDate={minDate}
                maxDate={maxDate}
                genderCat={genderCat}
                relationBenefitEnterprise={relationBenefitEnterprise}
                setRelationBenefitEnterprise={setRelationBenefitEnterprise}
                />
            )
        )
    );
  }
};

export default ConfirmCollab;
