import React from 'react'

const LoaderSurvey = () => {
    return (
        <div className="row d-flex align-items-center justify-content-center">
            <div className="col-12 d-flex align-items-center justify-content-center">
                <img src="../img/element4.png" alt="guaysito_espera" style={{ width: '25%' }} />
            </div>
            <div className="col-12 d-flex align-items-center justify-content-center">
                <p className="text-center font-medium-2 text-blue-sec">Espere unos segundos, estamos validando algunos parámetros.</p>
            </div>
        </div>
    )
}

export default LoaderSurvey
