import React, { useState, useEffect, useRef } from 'react'
// TODO: API calls
import { getMarketStudiesQuiz } from '../../Models/FrontSurvey_model'
// TODO: Components
import Questions from './Questions'
// TODO: Generic Func
import { getJwt } from '../../../../lib/auth'
import { connect } from 'react-redux'
// TODO: Tools
import $ from "jquery"

const ModalController = ({ general }) => {
    const [survey, setSurvey] = useState([])
    const [formProps, setFormProps] = useState({
        title: '',
        briefing: '',
    })
    const { logo } = general.enterpriseInScope

    const {
        title,
        briefing,
    } = formProps
    const [showCloseBtn, setShowCloseBtn] = useState(false)
    const [count, setCount] = useState(0)
    const [idCollab, setIdCollab] = useState(0)
    useEffect(() => {
        setIdCollab(general.enterpriseInScope.idCollab)
        getMarketStudiesQuiz(getJwt('enterprise'), getJwt('branch'), general.enterpriseInScope.idCollab).then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                        setSurvey(response.quiz)
                        setFormProps({
                            title: response.title,
                            briefing: response.briefing,
                        })
                        $('#frontSurvey').show()
                        break
                    default:
                        break
                }
            }
        })
    }, [general.enterpriseInScope.idCollab])

    useEffect(() => {
        if(briefing !== '' && briefing !== undefined){
            const inst = document.querySelector('#txtInstrucciones');
            inst.innerHTML = briefing
          }

    }, [briefing])
    

    // Counter functions
    function useInterval(callback, delay) {
        const savedCallback = useRef()
        // Remember the latest function.
        useEffect(() => {
            savedCallback.current = callback
        }, [callback])
        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current()
            }
            if (delay !== null) {
                let id = setInterval(tick, delay)
                return () => clearInterval(id)
            }
        }, [delay])
    }
    // Counter triggers
    useInterval(() => {
        if (count === 5) {
            setShowCloseBtn(true)
        } else {
            setCount(count + 1)
        }
    }, 1000)
    return (
        <div
            className="modal"
            id="frontSurvey"
            style={{ backgroundColor: 'rgba(255,255,255,0.5)' }}
            data-backdrop="false"
        >
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-body scrollbar-1">
                        <div className='row'>
                            <div className='col-12 text-center mb-2'>
                                {
                                    showCloseBtn ? (
                                        <button
                                            type="button"
                                            className="close"
                                            onClick={() => {
                                                $('#frontSurvey').hide()
                                                sessionStorage.setItem('frontSurvey', 1)
                                            }}
                                        >&times;</button>
                                    ) : (
                                        <span className='font-small-2'>Podrá cerrar la encuesta en: {count} / 5</span>
                                    )
                                }
                            </div>
                            <div className='col-12 mb-2'>
                                <div className='row'>
                                    <div className='col-sm-12 col-md-3 col-lg-3 d-flex justify-content-center flex-wrap align-content-center'>
                                        {
                                            logo ? (
                                                <img
                                                    src={logo}
                                                    alt="Logo de tu organización"
                                                    className="w-50"
                                                />
                                            ) : (
                                                ''
                                            )
                                        }
                                    </div>
                                    <div className='col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center flex-wrap align-content-center'>
                                        <h3 className='text-dark font-weight-bold'>{title}</h3>
                                    </div>
                                    <div className='col-sm-12 col-md-3 col-lg-3 d-flex justify-content-center flex-wrap align-content-center'>
                                        <img
                                            src="./../img/guay-logo.png"
                                            alt="Logo de tu organización"
                                            className="w-50"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className='col-12 mb-2 text-center'>
                                <h3 className='text-dark font-weight-bold'>{title}</h3>
                            </div> */}
                            <div className='col-12 mb-2 text-center'>
                                <span>Tu participación es muy importante para esta encuesta personalizada. Por favor contesta estas {survey.length} preguntas. <span className='font-weight-bold'>#somosguay</span></span>
                            </div>
                            {
                                briefing !== '' && briefing !== undefined ? (
                                    <div className='col-12 mb-2'>
                                       <p id="txtInstrucciones" className='font-small-2'></p> 
                                    </div>
                                ) : null
                            }
                            <Questions
                                survey={survey}
                                idCollab={idCollab}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
const mapStateToProps = ({ general }) => {
    return {
        general
    }
}

export default connect(mapStateToProps)(ModalController);
// export default ModalController