import React from 'react'
import { useDropzone } from 'react-dropzone'
import * as toastr from 'toastr'
import 'toastr/build/toastr.css'
import { subirArchivo } from '../../Models/Collaborators_model';

function DropZone({ actualizarSteps, enterpriseId, setLoading, setupdate = () => { } }) {

    /**
     * Funcion para manejar cuando se aceptan o no archivos
     */
    const onDrop = (acceptedFiles, fileRejections) => {
        setLoading(true);
        if (fileRejections.length > 0) {
            setLoading(false);
            toastr.error('El archivo que utilizaste no es compatible con la plataforma. Por favor descarga la plantilla.', '¡Ooops!');
        } else {
            subirArchivo(acceptedFiles, enterpriseId, 0, 0).then((response) => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            toastr.success('Archivo cargado correctamente.', 'Genial!');
                            actualizarSteps(1);
                            setupdate(1)
                            break;
                        case 200:
                            toastr.success('Archivo cargado correctamente.', 'Genial!');
                            actualizarSteps(1);
                            setupdate(1)
                            break;
                        case 99:
                            setLoading(false);
                            toastr.error('Hubo un error inesperado al subir el archivo, descarga la plantilla e intenta nuevamente.', '¡Error!');
                            break;
                        case 404:
                            setLoading(false);
                            toastr.error('Hubo un error inesperado al subir el archivo, recarga la página y vuelve a intentar.', '¡Error!');
                            break;
                        case 1001:
                            setLoading(false);
                            toastr.warning('Archivo con errores, por favor checa tus datos y vuelve a intentarlo.', '¡Ojo!');
                            break;
                        case 1002:
                            setLoading(false);
                            toastr.warning('El archivo que utilizaste es de una versión pasada, ya no es compatible con la plataforma. Por favor descargué nuevamente la plantilla y corroboré la información', 'Ups, al parecer cambio la estructura del archivo');
                            break;
                        case 1003:
                            setLoading(false);
                            toastr.warning('Tienes correos duplicados. Por favor verifica tu plantilla.', '¡Ooops!');
                            break;
                        case 1004:
                            setLoading(false);
                            toastr.warning('Tienes teléfonos duplicados. Por favor verifica tu plantilla.', '¡Ooops!');
                            break;
                        case 1006:
                            setLoading(false);
                            toastr.warning('Verifica los datos de los colaboradores que tienen plan dental en tu plantilla.', '¡Ooops!');
                            break;
                        default:
                            setLoading(false);
                            toastr.error('Error inesperado.', '¡Error!');
                            break;
                    }
                } else {
                    setLoading(false);
                    toastr.error('Error inesperado.', '¡Error!');
                }
            });
        }
    };

    /**
     * Inicializacion de metodos, parametros y validaciones
     */
    const {
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: '.xlsx',
        maxFiles: 1,
        onDrop
    });

    return (
        <section>
            <div {...getRootProps({ className: 'dropzone row contenedor-alta pointer' })}>
                <input {...getInputProps()} />
                <div className="col-12 col-md-5 py-2">
                    <i className="ft-monitor icon-big-size text-blue"></i>
                    <p className="font-medium-2">Arrastrar y soltar aqu&iacute;</p>
                </div>
                <div className="col-12 col-md-2 d-flex align-items-end justify-content-center">
                    <p className="text-center mb-0 mb-md-4">&oacute;</p>
                </div>
                <div className="col-12 col-md-5 py-2">
                    <i className="ft-upload-cloud icon-big-size text-blue"></i>
                    <p className="font-medium-2 text-break">Selecciona el Archivo</p>
                </div>
            </div>
        </section>
    );
}


export default DropZone
