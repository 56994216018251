import React, { /* Fragment, */ useState, useEffect, useRef } from 'react';
import Header from './headerColaborador';
import Footer from '../footerApp';
import { redirectIfNotAuthenticated, getJwt, setJwt } from "../../../lib/auth";
import $ from "jquery";
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { getTutorials, setViewedTutorial, getuserData, getReportChannel, getsurveyLinksUser } from "./Model";
// import Swal from "sweetalert2";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
/* import { updProfilebyUser, getProfilebyUser } from '../profile/Model'; */
/* import ProfileModal from '../profile/ProfileModal'; */
import MainpageCarousel from './MainpageCarousel';
import { getProductsFree, getProductsUser } from '../Models/Users_model';
import { useHistory } from "react-router-dom";
import { getAllResourcesEnterprise } from '../Models/GuaykipediaModel';
import ActMonCollabForm from '../actions/actionsMonitoring/ActMonCollabForm';
/* import { getAddressByCP } from "../Models/Catalog_model"; */
import Menu from '../Menu';
import ComplainChannelModal from './ComplainChannelModal';
/* import { getAllPromotions } from '../Models/SponsorModel'; */
import SurveyModal from '../SurveyModal/SurveyModal';
/* import CreatePost from '../wall/CreatePost';
import PostStructure from '../wall/PostStructure'; */
/* import { getPublications } from '../Models/WallModel'; */
/* import LoaderApp from '../LoaderApp'; */
import BannerPromo from '../BannerPromo';
import { getBannersByEnterprise } from '../Models/SponsorModel';
import ModalController from '../SurveyModal/frontSurvey/ModalController';
import GenInfoModal from './profile/GenInfoModal';
import HelperApp from '../HelperApp';
import { connect } from 'react-redux';

import ProductCard from './ProductCard';
import {
    getPromotionsByEnterpriseAndCollab,
    getBenefitsByEnterpriseAndCollab,
} from '../Models/BenefitsModel';

import SurveyModalKiosk from '../kiosk/SurveyModal';

const InicioColaborador = ({ general }) => {

    const [data, updateData] = useState([]);
    const [enterpriseData, updateEnterpriseData] = useState([]);
    const [generalData, updateGeneralData] = useState([]);
    const [channelData, updateChannelData] = useState([]);
    const [listCategory, updateListCategory] = useState([]);
    /* const [setData, updateSetData] = useState({
        idProfile: 0,
        idUser: getJwt("jtw"),
        name: "",
        lastName: "",
        birthday: "",
        stateId: 0,
        townId: 0,
        neighborhoodId: 0,
        zipCode: "",
        phone: "",
        mainMail: "",
        altMail: ""
    });
    const { name, lastName, birthday, stateId, townId, zipCode, phone, mainMail, altMail } = setData; */
    // const [sliderData, setSliderData] = useState([]);
    // const [sliderData_2, setSliderData_2] = useState([]);
    const [sliderInner, setSliderInner] = useState([]);
    // const [sliderPage, setSliderPage] = useState(0);
    const [userRol, setUserRol] = useState(0);
    const history = useHistory();
    /* const [imgProfile, updateimgProfile] = useState(""); */
    const [tourClass, setTourClass] = useState("first-arrow-inactive");
    // const [showBtns, setShowBtns] = useState(false);
    const [urlComplainChannel, seturlComplainChannel] = useState("");
    /* const [catReactions, setCatReactions] = useState([]); */
    /* const [postData, setPostData] = useState([]); */
    /* const [tags, setTags] = useState([]); */
    const [bannerImg, setBannerImg] = useState('');

    const initialState = {
        activeCards: {
            benefits: false,
            promotions: false,
        }
    }
    const [products, setProducts] = useState([]);
    const [activeCards, setActiveCards] = useState(initialState.activeCards);
    // Arreglo de imagenes de circulos de tarjetas; esta ordenado uno arriba uno abajo para seguir la secuencia y sus estilos
    const arrCirclesImg = [
        {
            imgCircle:"./../img/inicioColaborador/circulo_verde_arriba.png",
            imgStyle:{position:'absolute', right:'0px', top:'0px',width:'50px'}
        },
        {
            imgCircle:"./../img/inicioColaborador/circulo_rosa_abajo.png",
            imgStyle:{position:'absolute', right:'0px', bottom:'0px',width:'50px'}
        },
        {
            imgCircle:"./../img/inicioColaborador/circulo_azul_arriba.png",
            imgStyle:{position:'absolute', right:'0px', top:'0px',width:'50px'}
        },
        {
            imgCircle:"./../img/inicioColaborador/circulo_amarillo_abajo.png",
            imgStyle:{position:'absolute', right:'0px', bottom:'0px',width:'50px'}
        },
        {
            imgCircle:"./../img/inicioColaborador/circulo_rosa_arriba.png",
            imgStyle:{position:'absolute', right:'0px', top:'0px',width:'50px'}
        },
        {
            imgCircle:"./../img/inicioColaborador/circulo_azul_abajo.png",
            imgStyle:{position:'absolute', right:'0px', bottom:'0px',width:'50px'}
        },
        {
            imgCircle:"./../img/inicioColaborador/circulo_amarillo_arriba.png",
            imgStyle:{position:'absolute', right:'0px', top:'0px',width:'50px'}
        },
        {
            imgCircle:"./../img/inicioColaborador/circulo_verde_abajo.png",
            imgStyle:{position:'absolute', right:'0px', bottom:'0px',width:'50px'}
        }
    ];

    const hashString = string => {
        try {
            let enc = window.btoa(string)
            const removeSlash = enc.replace('/', '')
            return removeSlash
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        try {
            getuserData(getJwt("jtw")).then((result) => {
                if (result) {
                    updateData(result);
                    updateGeneralData(result);
                    if (sessionStorage.getItem('showMessage')) {
                        // let message = "Faltan datos generales de la empresa. Favor de notificar al administrador."
                        for (let x in result.enterprise) {
                            if (result.enterprise[x].enterpriseId === parseInt(getJwt('enterprise'))) {
                                let message = "Faltan datos de la empresa " + result.enterprise[x].name + " por llenar. Da click en este mensaje."
                                if (result.enterprise[x].rolEnterpriseId !== 4) {
                                    sessionStorage.removeItem('showMessage');
                                    toastr.warning(message, "¡Ooops!", {
                                        onclick: function () {
                                            window.location = "/perfil-empresa/" + hashString("guaydigital") + result.enterprise[x].enterpriseId + "=" + hashString(result.enterprise[x].name);
                                        }
                                    });
                                } else {
                                    let message = "No tienes permiso para acceder en esta sección por esta empresa."
                                    sessionStorage.removeItem('showMessage');
                                    toastr.warning(message, "¡Ooops!");
                                }
                            }
                        }

                    }
                    /* getProfilebyUser(getJwt("jtw")).then((response) => {
                        if (response.code === 1) {
                            updateSetData({
                                ...setData,
                                idProfile: response.userProfile[0].idProfile,
                                name: response.userProfile[0].name === undefined || response.userProfile[0].name === "" ? result.name + " " + result.lastName : response.userProfile[0].name,
                                lastName: response.userProfile[0].lastName === undefined || response.userProfile[0].lastName === "" ? lastName : response.userProfile[0].lastName,
                                birthday: response.userProfile[0].birthday === undefined ? "" : response.userProfile[0].birthday,
                                stateId: response.userProfile[0].stateId,
                                townId: response.userProfile[0].townId,
                                neighborhoodId: response.userProfile[0].neighborhoodId,
                                zipCode: response.userProfile[0].zipCode === 0 ? "" : response.userProfile[0].zipCode,
                                phone: response.userProfile[0].phone === undefined ? "" : response.userProfile[0].phone,
                                mainMail: response.userProfile[0].mainMail === undefined ? result.correo : response.userProfile[0].mainMail,
                                altMail: response.userProfile[0].altMail === undefined ? "" : response.userProfile[0].altMail
                            });
                            if (response.userProfile[0].imgProfile !== undefined) {
                                updateimgProfile(response.userProfile[0].imgProfile);
                            }
                            if (response.userProfile[0].zipCode !== 0) {
                                getAddressByCP(response.userProfile[0].zipCode).then((respAddress) => {
                                    if (respAddress.length > 0) {
                                        $("#stateId, #townId, #neighborhoodId").html("")
                                        $("#stateId").append('<option value="' + respAddress[0].idState + '">' + respAddress[0].nameState + '</option>');
                                        $("#townId").append('<option value="' + respAddress[0].idTown + '">' + respAddress[0].nameTown + '</option>');
                                        $.each(respAddress, function (i, item) {
                                            $("#neighborhoodId").append('<option value="' + respAddress[i].idNeighborhood + '">' + respAddress[i].nameNeighborhood + '</option>');
                                            $("#neighborhoodId").attr("disabled", false);
                                        });
                                    }
                                });
                            }
                        } else {
                            updateSetData({
                                ...setData,
                                mainMail: result.correo,
                                name: result.name,
                                lastName: result.lastName
                            });
                        }
                    }); */
                    setUserRol(result.rol);
                    if (result.rol === 2 || result.rol === 3) {
                        getTutorials(getJwt("jtw"), 1).then((response) => {
                            if (response.code === 1) {
                                $('#tourModal').show();
                            }
                        });
                    } else {
                        getTutorials(getJwt("jtw"), 2).then((response) => {
                            if (response.code === 1) {
                                $('#tourModal').show();
                            }
                        });
                    }
                    updateEnterpriseData(result.enterprise);
                    for (let x in result.enterprise) {
                        if (result.enterprise[x].enterpriseId === parseInt(getJwt('enterprise'))) {
                            if (result.enterprise[x].channel === 0 || result.enterprise[x].channel === 1) {
                                getReportChannel(getJwt("enterprise")).then((response) => {
                                    if (response.code === 1) {
                                        updateChannelData(response);
                                    }
                                });
                            }
                        }
                    }
                    let products = [];
                    let products_2 = [];
                    const services = [
                        {
                            idProduct: 0,
                            idCategory: 1,
                            idSubcategory: 1,
                            productType: 3,
                            // imgCategory: "./../img/inicioColaborador/iconos_48.png",
                            imgCategory: "https://storage.googleapis.com/guay-api-production/Operacion/Home/Tarjeta_evaluaciones.png",
                            nameCategory: "Evaluaciones",
                            descCategory: "Queremos saber que piensas y como te sientes, para ello es importante responder las evaluaciones que tenemos consideradas para ti.",
                        },
                        {
                            idProduct: 0,
                            idCategory: 2,
                            idSubcategory: 2,
                            productType: 3,
                            // imgCategory: "./../img/inicioColaborador/iconos_49.png",
                            imgCategory: "https://storage.googleapis.com/guay-api-production/Operacion/Home/Tarjeta_buzon.png",
                            nameCategory: "Buzón",
                            descCategory: "Aquí puedes expresar las ideas de mejora, propuestas, quejas e iniciativas, tu voz siempre se escucha.",
                            // descCategory: "Tu voz siempre es escuchada, ideas de mejora, propuestas, quejas e iniciativas aquí las puedes expresar.",
                        },
                    ];
                    for (let x in services) {
                        if(parseInt(getJwt("enterprise")) !== 168){
                            products_2.push(services[x]);
                        }else if(services[x].idProduct === 0 && services[x].idCategory === 1 && services[x].idSubcategory === 1){
                            products_2.push(services[x]);
                        }
                        
                    }
                    /* if (getJwt('enterprise') === '84' ||
                        getJwt('enterprise') === '85' ||
                        getJwt('enterprise') === '86'
                    ) {
                        getAllPromotions(0).then(response => {
                            if (response) {
                                if (response.code === 1) {
                                    let data = {
                                        idProduct: 0,
                                        idCategory: 5,
                                        idSubcategory: 5,
                                        productType: 3,
                                        imgCategory: "./../img/inicioColaborador/iconos_49.png",
                                        nameCategory: "Promociones",
                                        descCategory: "Promociones para ti.",
                                    };
                                    products_2.push(data);
                                    products.push(data);
                                }
                            }
                        });
                    } */
                    let webinar = {
                        idProduct: 0,
                        idCategory: 4,
                        idSubcategory: 4,
                        productType: 3,
                        // imgCategory: "./../img/Elemento_43.png",
                        imgCategory: "https://storage.googleapis.com/guay-api-production/Operacion/Home/Tarjeta_webinars.png",
                        nameCategory: "Webinars",
                        descCategory: "Conoce sobre diversos temas de bienestar, salud física y emocional.",
                    };
                    if(parseInt(getJwt("enterprise")) !== 168){
                    products_2.push(webinar);
                    products.push(webinar);
                    }
                    if (getJwt("enterprise") !== "undefined") {
                        getProductsFree(getJwt("enterprise"), result.token).then((response) => {
                            let specialProducts = []
                            for (let x in response.listProducts) {
                                if (response.listProducts[x].idCategory === 4 || response.listProducts[x].idCategory === 5) {
                                    specialProducts.push({ ...response.listProducts[x], productType: 1 })
                                } else {
                                    products.push({ ...response.listProducts[x], productType: 1 });
                                    products_2.push({ ...response.listProducts[x], productType: 1 });
                                    if (response.listProducts[x].idProduct === 3 && response.listProducts[x].idCategory === 3 && response.listProducts[x].idSubcategory === 0) {
                                        let asistenciaguayData = response.idEnterprise + "-" + response.idCollab + "-" + response.idPeriod + "-" + response.idGroup;
                                        sessionStorage.setItem('Ag', asistenciaguayData);
                                    }
                                }
                            }
                            setProducts(specialProducts)
                            getProductsUser(getJwt("jtw")).then((response) => {
                                for (let x in response.listProducts) {
                                    if (response.listProducts[x].acquired !== 0) {
                                        products.push({ ...response.listProducts[x], productType: 2 });
                                        products_2.push({ ...response.listProducts[x], productType: 2 });
                                    }
                                }
                                let array = [];
                                let array_2 = [];
                                let arrayAux = [];
                                let arrayCompare = [];
                                for (let x in products) {
                                    if (array.length < 2) {
                                        if (array_2.length > 0) {
                                            array.push(array_2[0], products[x]);
                                            array_2 = [];
                                        } else {
                                            array.push(products[x]);
                                            array_2 = [];
                                        }
                                    } else {
                                        arrayAux.push(array);
                                        array_2.push(products[x]);
                                        array = [];
                                    }
                                }
                                for (let y in arrayAux) {
                                    for (let x in arrayAux[y]) {
                                        arrayCompare.push(arrayAux[y][x]);
                                    }
                                }
                                arrayAux.push(products.filter(elemento => arrayCompare.indexOf(elemento) === -1));
                                let array_3 = [];
                                let array_4 = [];
                                let arrayAux_2 = [];
                                let arrayCompare_2 = [];
                                for (let x in products_2) {
                                    if (array_3.length < 2) {
                                        if (array_4.length > 0) {
                                            array_3.push(array_4[0], products_2[x]);
                                            array_4 = [];
                                        } else {
                                            array_3.push(products_2[x]);
                                            array_4 = [];
                                        }
                                    } else {
                                        arrayAux_2.push(array_3);
                                        array_4.push(products_2[x]);
                                        array_3 = [];
                                    }
                                }
                                for (let y in arrayAux_2) {
                                    for (let x in arrayAux_2[y]) {
                                        arrayCompare_2.push(arrayAux_2[y][x]);
                                    }
                                }
                                arrayAux_2.push(products_2.filter(elemento => arrayCompare_2.indexOf(elemento) === -1));
                                // setSliderData(arrayAux);
                                // setSliderData_2(arrayAux_2);
                                // setSliderInner(arrayAux[0]);
                                let arrayCards = [];
                                arrayAux.forEach(array => {
                                    array.forEach(element => {
                                        arrayCards.push(element)
                                    })
                                });
                                let arrCards2 = []
                                let counter = 0
                                for (let x in arrayCards ) {
                                    if(counter === (arrCirclesImg.length)){
                                        arrCards2.push({...arrayCards[x], imgCircle: arrCirclesImg[0].imgCircle, imgCircleStyle: arrCirclesImg[0].imgStyle, imgCircleId: counter })
                                        counter = 1
                                    }else{
                                        arrCards2.push({...arrayCards[x], imgCircle: arrCirclesImg[counter].imgCircle, imgCircleStyle: arrCirclesImg[counter].imgStyle, imgCircleId: counter })
                                        counter++
                                    }
                                }
                                setSliderInner(arrCards2);
                                // setSliderInner(arrayCards);
                                let idCollab = 0;
                                for (let x in result.enterprise) {
                                    if (result.enterprise[x].enterpriseId === parseInt(getJwt("enterprise"))) {
                                        idCollab = result.enterprise[x].idCollab;
                                    }
                                }
                                if (getJwt('enterpriseName') === result.name) {
                                    // setSliderInner(arrayAux[0]);
                                    // if (arrayAux.length > 1) {
                                    //     setSliderInner(arrayAux[0]);
                                    //     setShowBtns(true);
                                    // } else {
                                    //     setSliderInner(arrayAux[0]);
                                    //     setShowBtns(false);
                                    // }
                                    let arrayCards = [];
                                    arrayAux.forEach(array => {
                                        array.forEach(element => {
                                            arrayCards.push(element)
                                        })
                                    });
                                    let arrCards2 = []
                                    let counter = 0
                                    for (let x in arrayCards ) {
                                        if(counter === (arrCirclesImg.length)){
                                            arrCards2.push({...arrayCards[x], imgCircle: arrCirclesImg[0].imgCircle, imgCircleStyle: arrCirclesImg[0].imgStyle, imgCircleId: counter })
                                            counter = 1
                                        }else{
                                            arrCards2.push({...arrayCards[x], imgCircle: arrCirclesImg[counter].imgCircle, imgCircleStyle: arrCirclesImg[counter].imgStyle, imgCircleId: counter })
                                            counter++
                                        }
                                    }
                                    setSliderInner(arrCards2);
                                    // setSliderInner(arrayCards);
                                } else {
                                    if (idCollab === 0) {
                                        // if (arrayAux.length > 1) {
                                        //     setSliderInner(arrayAux[0]);
                                        //     setShowBtns(true);
                                        // } else {
                                        //     setSliderInner(arrayAux[0]);
                                        //     setShowBtns(false);
                                        // }
                                        let arrayCards = [];
                                        arrayAux.forEach(array => {
                                            array.forEach(element => {
                                                arrayCards.push(element)
                                            })
                                        });
                                        let arrCards2 = []
                                        let counter = 0
                                        for (let x in arrayCards ) {
                                            if(counter === (arrCirclesImg.length)){
                                                arrCards2.push({...arrayCards[x], imgCircle: arrCirclesImg[0].imgCircle, imgCircleStyle: arrCirclesImg[0].imgStyle, imgCircleId: counter })
                                                counter = 1
                                            }else{
                                                arrCards2.push({...arrayCards[x], imgCircle: arrCirclesImg[counter].imgCircle, imgCircleStyle: arrCirclesImg[counter].imgStyle, imgCircleId: counter })
                                                counter++
                                            }
                                        }
                                        setSliderInner(arrCards2);
                                        // setSliderInner(arrayCards);
                                    } else {
                                        // if (arrayAux_2.length > 1) {
                                        //     setSliderInner(arrayAux_2[0]);
                                        //     setShowBtns(true);
                                        // } else {
                                        //     setSliderInner(arrayAux_2[0]);
                                        //     setShowBtns(false);
                                        // }
                                        let arrayCards = [];
                                        arrayAux_2.forEach(array => {
                                            array.forEach(element => {
                                                arrayCards.push(element)
                                            })
                                        });
                                        let arrCards2 = []
                                        let counter = 0
                                        for (let x in arrayCards ) {
                                            if(counter === (arrCirclesImg.length)){
                                                arrCards2.push({...arrayCards[x], imgCircle: arrCirclesImg[0].imgCircle, imgCircleStyle: arrCirclesImg[0].imgStyle, imgCircleId: counter })
                                                counter = 1
                                            }else{
                                                arrCards2.push({...arrayCards[x], imgCircle: arrCirclesImg[counter].imgCircle, imgCircleStyle: arrCirclesImg[counter].imgStyle, imgCircleId: counter })
                                                counter++
                                            }
                                        }
                                        setSliderInner(arrCards2);
                                        // setSliderInner(arrayCards);
                                    }
                                }
                            });
                        });
                    } else {
                        getProductsFree(0, result.token).then((response) => {
                            for (let x in response.listProducts) {
                                let id = `${response.listProducts[x].idProduct}-${response.listProducts[x].idCategory}-${response.listProducts[x].idSubcategory}`;
                                if (id === '1-1-0') {
                                    products.push({ ...response.listProducts[x], productType: 1 });
                                }
                            }
                            getProductsUser(getJwt("jtw")).then((response) => {
                                for (let x in response.listProducts) {
                                    if (response.listProducts[x].acquired !== 0) {
                                        products.push({ ...response.listProducts[x], productType: 2 });
                                    }
                                }
                                let products2 = []
                                let counter = 0
                                for (let x in products ) {
                                    if(counter === (arrCirclesImg.length)){
                                        products2.push({...products[x], imgCircle: arrCirclesImg[0].imgCircle, imgCircleStyle: arrCirclesImg[0].imgStyle, imgCircleId: counter })
                                        counter = 1
                                    }else{
                                        products2.push({...products[x], imgCircle: arrCirclesImg[counter].imgCircle, imgCircleStyle: arrCirclesImg[counter].imgStyle, imgCircleId: counter })
                                        counter++
                                    }
                                }
                                setSliderInner(products2);
                                // setSliderInner(products);
                                /* let array_3 = [];
                                let array_4 = [];
                                let arrayAux_2 = [];
                                let arrayCompare_2 = [];
                                for (let x in products_2) {
                                    if (array_3.length < 2) {
                                        if (array_4.length > 0) {
                                            array_3.push(array_4[0], products_2[x]);
                                            array_4 = [];
                                        } else {
                                            array_3.push(products_2[x]);
                                            array_4 = [];
                                        }
                                    } else {
                                        arrayAux_2.push(array_3);
                                        array_4.push(products_2[x]);
                                        array_3 = [];
                                    }
                                }
                                for (let y in arrayAux_2) {
                                    for (let x in arrayAux_2[y]) {
                                        arrayCompare_2.push(arrayAux_2[y][x]);
                                    }
                                }
                                arrayAux_2.push(products_2.filter(elemento => arrayCompare_2.indexOf(elemento) === -1)); */
                                // if (arrayAux_2.length > 1) {
                                //     setSliderInner(arrayAux_2[0]);
                                //     setShowBtns(true);
                                // } else {
                                //     setSliderInner(arrayAux_2[0]);
                                //     setShowBtns(false);
                                // }
                                /* let arrayCards = [];
                                arrayAux_2.forEach(array => {
                                    array.forEach(element => {
                                        arrayCards.push(element)
                                    })
                                });
                                setSliderInner(arrayCards); */
                            });
                        })
                    }
                }
            });
            if (getJwt("enterprise") !== "undefined") {
                getsurveyLinksUser(getJwt("jtw"), getJwt("enterprise")).then((response) => {
                    if (response.code === 1) {
                        let arrayAux = [];
                        for (let x in response.listProducts) {
                            for (let y in response.listProducts[x].listCategory) {
                                arrayAux.push(response.listProducts[x].listCategory[y]);
                            }
                        }
                        if (response.customerSurveys.length >= 1) {
                            let arrayCustomSurvey = [];
                            response.customerSurveys.forEach(element => {
                                arrayCustomSurvey.push({ ...element, status: 2, imgWeb: response.imgCustomerSurveys });
                            });
                            arrayAux.push({
                                title: "Encuestas personalizadas",
                                listCustomSurvey: arrayCustomSurvey
                            });
                        }
                        let img = [
                            "./../img/inicioColaborador/icono_circulo_magenta.png",
                            "./../img/inicioColaborador/icono_circulo_verde.png",
                            "./../img/inicioColaborador/icono_circulo_azul.png",
                            "./../img/inicioColaborador/icono_circulo_amarillo.png",
                            "./../img/inicioColaborador/icono_circulo_gris.png",
                        ];
                        let surveyArr = [];
                        let counter = 0;
                        for (let x in arrayAux) {
                            if (counter === 4) {
                                surveyArr.push({ ...arrayAux[x], icon_1: img[0], iconId: counter });
                                counter = 1;
                            } else {
                                if (arrayAux[x].idCategory === 1) {
                                    let newListSubCategorys = []
                                    for (let y in arrayAux[x].listSubCategorys) {
                                        newListSubCategorys.push({ ...arrayAux[x].listSubCategorys[y], icon_1: img[counter], iconId: counter })
                                        counter = counter + 1;
                                    }
                                    surveyArr.push({ ...arrayAux[x], listSubCategorys: newListSubCategorys });
                                } else {
                                    surveyArr.push({ ...arrayAux[x], icon_1: img[counter], iconId: counter });
                                    counter = counter + 1;
                                }
                            }
                        }
                        //console.log(surveyArr)
                        updateListCategory(surveyArr);
                    }
                });
            }
            if (getJwt('enterprise') === undefined || getJwt('enterprise') === 'undefined') { }
            else {
                getBannersByEnterprise(getJwt('enterprise'), 2).then(response => {
                    if (response) {
                        switch (response.code) {
                            case 1:
                                setBannerImg(response.resource);
                                break;
                            case 1002:

                                break;
                            default:
                                toastr.error('Problemas técnicos. Favor de contactarse con el equipo de soporte. Code error: ' + response.code, '¡Ooops!');
                                break;
                        }
                    } else {
                        toastr.error('Problemas con el servidor. Favor de intentar nuevamente más tarde.', '¡Ooops!');
                    }
                });
            }
            /* getPublications(getJwt('jtw'), getJwt('enterprise'), getJwt('rol'), 0).then(response => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            setTags(response.catPublication);
                            let icons = [
                                'bi bi-hand-thumbs-up pointer text-base font-large-1',
                                'bi bi-hand-thumbs-down pointer text-base font-large-1',
                                'bi bi-award pointer text-base font-large-1',
                                'bi bi-emoji-dizzy pointer text-base font-large-1',
                                'bi bi-emoji-laughing pointer text-base font-large-1',
                            ];
                            let colors = [
                                'font-medium-1 align-top text-green-mine-2 font-weight-bold',
                                'font-medium-1 align-top text-pink-mine font-weight-bold',
                                'font-medium-1 align-top text-guay-blue font-weight-bold',
                                'font-medium-1 align-top text-yellow-mine font-weight-bold',
                                'font-medium-1 align-top text-green-mine-2 font-weight-bold',
                            ];
                            let arr = response.catReactions.map((data, index) => {
                                return ({
                                    idReaction: data.idReaction,
                                    description: data.description,
                                    icon: icons[index],
                                    color: colors[index],
                                });
                            });
                            setCatReactions(arr);
                            setPostData(response.publications);
                            break;
                        case 404:
                        case 1001:
                        case 1002:
                            toastr.error(response.message, "¡Ooops!");
                            break;
                        case 1003:
                            
                            break;
                        default:
                            toastr.error("No se pudo conectar con el servidor. Intente más tarde.", "¡Ooops!");
                            break;
                    }
                }
            }); */
        } catch (error) {
            console.log(error);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let obj = {}
        if (getJwt("enterprise") !== 'undefined') {
            getPromotionsByEnterpriseAndCollab(getJwt("enterprise"), general.enterpriseInScope.idCollab).then(response => {
                if (response.validation) {
                    if (response.data.code !== 1001) {
                        obj = {
                            ...activeCards,
                            promotions: true,
                        }
                        //console.log(obj)
                        getBenefitsByEnterpriseAndCollab(getJwt("enterprise"), general.enterpriseInScope.idCollab).then(response => {
                            if (response.validation) {
                                if (response.data.code !== 1001) {
                                    obj = {
                                        ...obj,
                                        benefits: true,
                                    }
                                    //console.log(obj)
                                    setActiveCards(obj)
                                } else {
                                    setActiveCards(obj)
                                }
                            }
                        })
                    } else {
                        getBenefitsByEnterpriseAndCollab(getJwt("enterprise"), general.enterpriseInScope.idCollab).then(response => {
                            if (response.validation) {
                                if (response.data.code !== 1001) {
                                    obj = {
                                        ...obj,
                                        benefits: true,
                                    }
                                    setActiveCards(obj)
                                }
                            }
                        })
                    }
                }
            })
        }
        // eslint-disable-next-line
    }, [])

    // const sliderFunction = (indicator) => {
    //     let page;
    //     let arrayNum = sliderData.length - 1;
    //     let arrayNum_2 = sliderData_2.length - 1;
    //     if (getJwt('enterpriseName') === data.name) {
    //         if (indicator === 1) {
    //             page = sliderPage + 1;
    //             if (page <= arrayNum) {
    //                 setSliderInner(sliderData[page]);
    //                 setSliderPage(page);
    //             }
    //         } else {
    //             page = sliderPage - 1;
    //             if (page >= 0) {
    //                 setSliderInner(sliderData[page]);
    //                 setSliderPage(page);
    //             }
    //         }
    //     } else {
    //         if (indicator === 1) {
    //             page = sliderPage + 1;
    //             if (page <= arrayNum_2) {
    //                 setSliderInner(sliderData_2[page]);
    //                 setSliderPage(page);
    //             } else {
    //                 setSliderInner(sliderData_2[0]);
    //                 setSliderPage(0);
    //             }
    //         } else {
    //             page = sliderPage - 1;
    //             let lastPage = sliderData_2.length - 1;
    //             if (page >= 0) {
    //                 setSliderInner(sliderData_2[page]);
    //                 setSliderPage(page);
    //             } else {
    //                 setSliderInner(sliderData_2[lastPage]);
    //                 setSliderPage(lastPage);
    //             }
    //         }
    //     }
    // }

    const steps = [
        {
            selector: '[data-tut="tourColaborador-step-1"]',
            content: () => (
                <div>
                    <p>Da click en nuestro logo para regresar a la Pantalla de Inicio</p>
                </div>
            ),
            style: {
                backgroundColor: '#9cb537',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center',
                padding: '40px'
            }
        },
        /* {
            selector: '[data-tut="tourColaborador-step-2"]',
            content: () => (
                <div>
                    <p>Conoce nuestros productos entrando en la tienda guay</p>
                </div>
            ),
            style: {
                backgroundColor: '#9cb537',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center',
                padding: '40px'
            }
        }, */
        {
            selector: '[data-tut="tourColaborador-step-3"]',
            content: () => (
                <div>
                    <p>Administra y configura tus servicios</p>
                </div>
            ),
            style: {
                backgroundColor: '#9cb537',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center',
                padding: '40px'
            }
        },
        {
            selector: '[data-tut="tourColaborador-step-4"]',
            content: () => (
                <div>
                    <p>Administra tu perfil y cuentas</p>
                </div>
            ),
            style: {
                backgroundColor: '#9cb537',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center',
                padding: '40px'
            }
        },
        /* {
            selector: '[data-tut="tourColaborador-step-5"]',
            content: () => (
                <div>
                    <p>Aquí encontrarás tus alertas y comunicación</p>
                </div>
            ),
            style: {
                backgroundColor: '#9cb537',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center',
                padding: '40px'
            }
        }, */
        {
            selector: '[data-tut="tourColaborador-step-6"]',
            content: () => (
                <div>
                    <p>¡Conoce más! Entérete de temas relevantes e información general</p>
                </div>
            ),
            style: {
                backgroundColor: '#9cb537',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center',
                padding: '40px'
            }
        },
        {
            selector: '[data-tut="tourColaborador-step-7"]',
            content: () => (
                <div>
                    <p>Encuentra tus servicios y beneficios guay</p>
                </div>
            ),
            style: {
                backgroundColor: '#9cb537',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center',
                padding: '40px'
            }
        },
        {
            selector: '[data-tut="tourColaborador-step-8"]',
            content: () => (
                <div>
                    <p>Cuenta con nosotros para resolver tus dudas e inquietudes</p>
                </div>
            ),
            style: {
                backgroundColor: '#9cb537',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center',
                padding: '40px'
            }
        }
    ];

    const steps_2 = [
        {
            selector: '[data-tut="tourColaborador-step-1"]',
            content: () => (
                <div>
                    <p>Da click en nuestro logo para regresar a la Pantalla de Inicio</p>
                </div>
            ),
            style: {
                backgroundColor: '#9cb537',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center',
                padding: '40px'
            }
        },
        /* {
            selector: '[data-tut="tourColaborador-step-2"]',
            content: () => (
                <div>
                    <p>Conoce nuestros productos entrando en la tienda guay</p>
                </div>
            ),
            style: {
                backgroundColor: '#9cb537',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center',
                padding: '40px'
            }
        }, */
        {
            selector: '[data-tut="tourColaborador-step-4"]',
            content: () => (
                <div>
                    <p>Administra tu perfil y cuentas</p>
                </div>
            ),
            style: {
                backgroundColor: '#9cb537',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center',
                padding: '40px'
            }
        },
        /* {
            selector: '[data-tut="tourColaborador-step-5"]',
            content: () => (
                <div>
                    <p>Aquí encontrarás tus alertas y comunicación</p>
                </div>
            ),
            style: {
                backgroundColor: '#9cb537',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center',
                padding: '40px'
            }
        }, */
        {
            selector: '[data-tut="tourColaborador-step-6"]',
            content: () => (
                <div>
                    <p>¡Conoce más! Entérete de temas relevantes e información general</p>
                </div>
            ),
            style: {
                backgroundColor: '#9cb537',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center',
                padding: '40px'
            }
        },
        {
            selector: '[data-tut="tourColaborador-step-7"]',
            content: () => (
                <div>
                    <p>Encuentra tus servicios y beneficios guay</p>
                </div>
            ),
            style: {
                backgroundColor: '#9cb537',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center',
                padding: '40px'
            }
        },
        {
            selector: '[data-tut="tourColaborador-step-8"]',
            content: () => (
                <div>
                    <p>Cuenta con nosotros para resolver tus dudas e inquietudes</p>
                </div>
            ),
            style: {
                backgroundColor: '#9cb537',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center',
                padding: '40px'
            }
        }
    ];

    const [isTourOpen, setIsTourOpen] = useState(false);

    const startIntro = () => {
        try {
            $("#tourModal").hide();
            setIsTourOpen(true);
        } catch (error) {
            console.log(error);
        }
    }

    const noIntro = () => {
        try {
            $("#tourModal").hide();
            enableBodyScroll(true);
            /* if (name !== '' && lastName !== '' && birthday !== '' && stateId !== 0 && townId !== 0 && zipCode !== 0 && phone !== '' && mainMail !== '') {
                if (userRol === 2 || userRol === 3) {
                    setViewedTutorial(getJwt("jtw"), 1);
                } else {
                    setViewedTutorial(getJwt("jtw"), 2);
                }
            } else {
                $("#modal-1").show();
                if (userRol === 2 || userRol === 3) {
                    setViewedTutorial(getJwt("jtw"), 1);
                } else {
                    setViewedTutorial(getJwt("jtw"), 2);
                }
            } */
            $("#modal-1").show();
            if (userRol === 2 || userRol === 3) {
                setViewedTutorial(getJwt("jtw"), 1);
            } else {
                setViewedTutorial(getJwt("jtw"), 2);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const endIntro = () => {
        try {
            enableBodyScroll(true);
            /* if (name !== '' && lastName !== '' && birthday !== '' && stateId !== 0 && townId !== 0 && zipCode !== 0 && phone !== '' && mainMail !== '') {
                if (userRol === 2 || userRol === 3) {
                    setViewedTutorial(getJwt("jtw"), 1);
                } else {
                    setViewedTutorial(getJwt("jtw"), 2);
                }
            } else {
                $("#modal-1").show();
                if (userRol === 2 || userRol === 3) {
                    setViewedTutorial(getJwt("jtw"), 1);
                } else {
                    setViewedTutorial(getJwt("jtw"), 2);
                }
            } */
            $("#modal-1").show();
            if (userRol === 2 || userRol === 3) {
                setViewedTutorial(getJwt("jtw"), 1);
            } else {
                setViewedTutorial(getJwt("jtw"), 2);
            }
        } catch (error) {
            console.log(error);
        }
    }

    /* const handleOpenURL = (url, description, title, status) => {
        try {
            switch (status) {
                case 1:
                    toastr.info('Ejercicio pendiente por lanzar.');
                    break;
                case 2:
                    Swal.fire({
                        title: '<p class="text-white mb-2">¿Deseas Continuar?</p>',
                        text: "Se abrirá una nueva ventana para realizar la evaluación " + title + ": " + description,
                        showCloseButton: true,
                        showCancelButton: true,
                        focusCancel: true,
                        cancelButtonText: 'Cancelar',
                        showConfirmButton: true,
                        confirmButtonText: "Sí, continuar",
                        reverseButtons: true,
                        dangerMode: true
                    }).then((willDelete) => {
                        if (willDelete.isConfirmed) {
                            window.open(url);
                        }
                    });
                    $(".swal2-title").addClass("bg-header-swal");
                    $(".swal2-actions").addClass("w-100 justify-content-around");
                    $(".swal2-confirm").removeClass("swal2-styled");
                    $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                    $(".swal2-cancel").removeClass("swal2-styled");
                    $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                    break;
                case 3:
                    toastr.info('Periodo del ejercicio cerrado.');
                    break;
                default:
                    toastr.error('Error al mostrar ejercicio. Por favor, intentelo más tarde.');
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    } */

    const closeModal = (e) => {
        try {
            let id = e.target.id;
            switch (id) {
                case "close-1":
                    $('#modal-1').hide();
                    $('#profileModal').show();
                    break;
                case "close-2":
                    /* let validated = validationData();
                    if (validated === true) {
                        $('#profileModal').hide();
                    } */
                    $('#profileModal').hide();
                    break;
                default:
                    $('#modal-1').hide();
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    }

    /* const onChangeHandler = (e) => {
        try {
            let fileTypes = [
                "image/png",
                "image/jpg",
                "image/jpeg",
                "image/tiff"
            ]
            let correct = false;
            let reader = new FileReader();
            let file = e.target.files[0];
            for (let x in fileTypes) {
                if (fileTypes[x] === e.target.files[0].type) {
                    correct = true;
                }
            }
            if (correct === true) {
                reader.onload = function (e) {
                    $('#img-person').attr('src', e.target.result);
                };
                reader.readAsDataURL(e.target.files[0]);
                updateFiles({
                    ...files,
                    file
                });
            } else {
                toastr.error('El tipo de archivo no es correcto. Los tipos de archivo son: .png, .tiff, .jpg, .jpeg.', '¡Ooops!');
            }
        } catch (error) {
            console.log(error);
        }
    } */

    /* const renderFilePers = () => {
        $("#file").click();
    } */

    /* const validationData = () => {
        let validated = 0;
        if (name === "") {
            $("#name").addClass("border-danger-guay");
            toastr.error('Falta llenar el campo: Nombre', '¡Ooops!');
        } else {
            validated = validated + 1;
        }
        if (lastName === "") {
            $("#lastName").addClass("border-danger-guay");
            toastr.error('Falta llenar el campo: Apellidos', '¡Ooops!');
        } else {
            validated = validated + 1;
        }
        if (mainMail === "") {
            $("#mainMail").addClass("border-danger-guay");
            toastr.error('Falta llenar el campo: Correo de contacto', '¡Ooops!');
        } else {
            validated = validated + 1;
        }
        if (validated === 3) {
            return true;
        }
    } */

    /* const setProfileData = (e) => {
        e.preventDefault();
        try {
            let validated = validationData();
            if (validated === true) {
                let data = {
                    ...setData,
                    name: name === "" ? "" : name,
                    lastName: lastName === "" ? "" : lastName,
                    birthday: birthday === "" ? "" : setData.birthday.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1'),
                    stateId: zipCode === "" || zipCode < 5 ? 0 : stateId,
                    townId: zipCode === "" || zipCode < 5 ? 0 : townId,
                    neighborhoodId: zipCode === "" || zipCode < 5 ? 0 : parseInt(setData.neighborhoodId),
                    zipCode: zipCode === "" || zipCode < 5 ? 0 : parseInt(setData.zipCode),
                    phone: phone === "" ? "" : phone,
                    mainMail: mainMail === "" ? "" : mainMail,
                    altMail: altMail === "" ? "" : altMail
                }
                let formData = new FormData();
                formData.append("file", files.file);
                formData.append("request", JSON.stringify(data));
                updProfilebyUser(formData).then((response) => {
                    if (response.code === 1) {
                        $('#modal-2').hide();
                        toastr.success('Información actualizada correctamente.', '¡Bien hecho!');
                        let userData = {
                            id: generalData.token,
                            enterprise: getJwt("enterprise"),
                            rol: getJwt("rol"),
                            help: generalData.help,
                            tkc: generalData.tk,
                            email: generalData.correo,
                            session: generalData.session,
                            enterpriseName: name,
                            enterpriseInitials: getJwt("enterpriseInitials"),
                            userName: generalData.name,
                        }
                        setJwt(userData);
                        window.location.reload();
                    } else {
                        $('#modal-2').hide();
                        toastr.error('Información no se actualizó correctamente.', '¡Ooops!');
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }

    } */

    const handleServicesActions = (productType, idProduct, idCategory, idSubcategory) => {
        let id = "";
        if (productType === 1) {
            id = "F-" + idProduct + "-" + idCategory + "-" + idSubcategory + "";
        }
        if (productType === 2) {
            id = "P-" + idProduct + "-" + idCategory + "-" + idSubcategory + "";
        }
        if (productType === 3) {
            id = "E-" + idProduct + "-" + idCategory + "-" + idSubcategory + "";
        }
        switch (id) {
            case "E-0-1-1":
                if (listCategory.length > 0) {
                    $('#surveysModal').show();
                } else {
                    toastr.info('No tienes evaluaciones por responder.', '¡Hola ' + data.name + '!');
                }
                break;
            case "E-0-2-2":
                if (channelData.code === 1) {
                    /* handleChannel(); */
                    seturlComplainChannel(channelData.urlChannel);
                    $("#complainChannel").show();
                } else {
                    toastr.info('El buzón está desactivado.', '¡Hola ' + data.name + '!')
                }
                break;
            case "E-0-3-3":
                toastr.info('La Asistencia guay no está activa por el momento.', '¡Hola ' + data.name + '!');
                break;
            case "E-0-4-4":
                history.push("/webinar");
                break;
            case "E-0-5-5":
                history.push("/promociones");
                break;
            case "F-1-4-0":
                history.push("/beneficios");
                break;
            case "F-1-1-0":
                getAllResourcesEnterprise(getJwt("enterprise")).then((response) => {
                    if (response) {
                        if (response.code === 1) {
                            let adquired = 0;
                            for (let x in response.listProducts) {
                                for (let y in response.listProducts[x].listCategorys) {
                                    if (response.listProducts[x].listCategorys[y].listSubCategorys !== undefined) {
                                        for (let z in response.listProducts[x].listCategorys[y].listSubCategorys) {
                                            if (response.listProducts[x].listCategorys[y].listSubCategorys[z].adquired) {
                                                adquired = adquired + 1;
                                            }
                                        }
                                    }
                                    if (response.listProducts[x].listCategorys[y].adquired !== undefined) {
                                        adquired = adquired + 1;
                                    }
                                }
                            }
                            if (adquired === 0) {
                                toastr.info('No tienes productos adquiridos.', '¡Hola ' + data.name + '!');
                            } else {
                                if (data.name === getJwt("enterpriseName")) {
                                    let userData = {
                                        id: generalData.token,
                                        enterprise: enterpriseData[0].enterpriseId,
                                        rol: enterpriseData[0].rolEnterpriseId,
                                        help: generalData.help,
                                        tkc: generalData.tk,
                                        email: generalData.correo,
                                        session: generalData.session,
                                        enterpriseName: enterpriseData[0].name,
                                        enterpriseInitials: enterpriseData[0].initials,
                                        userName: generalData.name,
                                    }
                                    setJwt(userData);
                                    history.push("/guaykipedia");
                                } else {
                                    history.push("/guaykipedia");
                                }
                            }
                        } else {
                            toastr.info('No tienes productos adquiridos.', '¡Hola ' + data.name + '!');
                        }
                    }
                });
                break;
            case "F-2-2-0":
                toastr.info('El Perfil de personalidad está por liberarse... ¡espéralo muy pronto!', '¡Hola ' + data.name + '!');
                break;
            case "F-3-3-0":
                $('#modalFormActMon').show();
                /* history.push("/colaborador-tracking"); */
                break;
            default:
                break;
        }
    }
    /* const [requestCounter, setRequestCounter] = useState(1);
    const [noDataFlag, setNoDataFlag] = useState(false);
    const [showLoader, setShowLoader] = useState(false); */
    const listInnerRef = useRef();
    const onScroll = () => {
        /* if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                if (!noDataFlag) {
                    setShowLoader(true);
                    getPublications(getJwt('jtw'), getJwt('enterprise'), getJwt('rol'), requestCounter).then(response => {
                        if (response) {
                            switch (response.code) {
                                case 1:
                                    let newArr = postData.map(data => data);
                                    for (let x in response.publications) {
                                        newArr.push(response.publications[x]);
                                    }
                                    setPostData(newArr);
                                    setRequestCounter(requestCounter + 1);
                                    setShowLoader(false);
                                    break;
                                case 1003:
                                    setNoDataFlag(true);
                                    setShowLoader(false);
                                    break;
                                case 404:
                                case 1001:
                                case 1002:
                                    toastr.error(response.message, "¡Ooops!");
                                    break;
                                default:
                                    toastr.error("No se pudo conectar con el servidor. Intente más tarde.", "¡Ooops!");
                                    break;
                            }
                        }
                    });
                }
            }
        } */
    };
    if (redirectIfNotAuthenticated()) {
        return (
            <div className="app-content new-guay-plataform bootomFooter">
                <div className="content-overlay">
                    <Header />
                    <Menu />
                    <Tour
                        steps={getJwt("rol") === "3" || getJwt("rol") === "2" ? steps : steps_2}
                        isOpen={isTourOpen}
                        onRequestClose={() => setIsTourOpen(false)}
                        onAfterOpen={() => disableBodyScroll(true)}
                        onBeforeClose={() => endIntro()}
                        className={tourClass}
                        getCurrentStep={(curr) => {
                            if (curr === 0) {
                                setTourClass("first-arrow-inactive")
                            } else if (getJwt("rol") === "3" || getJwt("rol") === "2") {
                                if (curr === 7) {
                                    setTourClass("last-arrow-inactive")
                                } else {
                                    setTourClass("")
                                }
                            } else {
                                if (curr === 6) {
                                    setTourClass("last-arrow-inactive")
                                } else {
                                    setTourClass("")
                                }
                            }
                        }}
                    />
                    <ComplainChannelModal
                        urlComplainChannel={urlComplainChannel}
                    />
                    {
                        sessionStorage.getItem('surveyModal') === null ? (
                            <SurveyModal />
                        ) : (
                            sessionStorage.getItem('surveyModal') !== '1' ? (
                                <SurveyModal />
                            ) : (null)
                        )
                    }
                    {
                        sessionStorage.getItem('frontSurvey') === null ? (
                            <ModalController />
                        ) : (
                            sessionStorage.getItem('frontSurvey') !== '1' ? (
                                <ModalController />
                            ) : (null)
                        )
                    }
                    <div className="content-wrapper bootomFooter">
                        <div className="content-header row"></div>
                        <div
                            className="content-body hide-scroll"
                            style={{ height: '85vh', overflow: 'auto' }}
                            onScroll={onScroll}
                            ref={listInnerRef}
                        >
                            <HelperApp />
                            <div className="container-fluid font-quicksand">
                                <div className="row">
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12" >
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='row' /* className='row sticky-top' */>
                                                    <MainpageCarousel handleServicesActions={handleServicesActions} />
                                                    {
                                                        window.screen.width <= 576 && (
                                                            <div className='col-12 banner-phone'>
                                                                {
                                                                    bannerImg === '' ? '' : <BannerPromo
                                                                        bannerImg={bannerImg}
                                                                        setBannerImg={setBannerImg}
                                                                    />
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    <div className="col-12 mx-auto mt-2" data-tut="tourColaborador-step-7">
                                                        <div className="row d-flex">
                                                            {
                                                                
                                                                sliderInner.map((data, index) =>
                                                                   
                                                                    // <div className="col-sm-12 col-md-6 col-lg-4 col-xxl-3 mb-1" id={data.productType === 1 ? ("F-" + data.idProduct + "-" + data.idCategory + "-" + data.idSubcategory) : ("P-" + data.idProduct + "-" + data.idCategory + "-" + data.idSubcategory)} key={index}>
                                                                    //     <div className={data.idProduct === 0 && data.idCategory === 2 ? (channelData.code === 1 ? ("card h-100 div-shadow-style-1 pointer") : ("card h-100 div-dissable-style-1 pointer")) : ("card h-100 div-shadow-style-1 pointer")} onClick={() => handleServicesActions(data.productType, data.idProduct, data.idCategory, data.idSubcategory)}>
                                                                    //         <div className="card-header carousel-card-title-1">{data.nameCategory}</div>
                                                                    //         <div className="card-body d-flex flex-wrap align-content-center">
                                                                    //             <div className="row">
                                                                    //                 <div className="col-sx-12 col-sm-12 col-md-4 col-lg-3 d-flex justify-content-center">
                                                                    //                     <img className="img-card-responsive" src={data.imgCategory} alt="news" />
                                                                    //                 </div>
                                                                    //                 <div className="col-sx-12 col-sm-12 col-md-8 col-lg-9 font-size-12 d-flex flex-wrap align-content-center text-justify">
                                                                    //                     <p>{data.descCategory}</p>
                                                                    //                 </div>
                                                                    //             </div>
                                                                    //         </div>
                                                                    //     </div>
                                                                    // </div>
                                                                    <div 
                                                                        key={index}
                                                                        className="col-sm-12 col-md-6 col-lg-3 col-xxl-3 mb-1"
                                                                        id={data.productType === 1 ? ("F-" + data.idProduct + "-" + data.idCategory + "-" + data.idSubcategory) : ("P-" + data.idProduct + "-" + data.idCategory + "-" + data.idSubcategory)} 
                                                                    >
                                                                        <div 
                                                                            className={data.idProduct === 0 && data.idCategory === 2 ? (channelData.code === 1 ? ("card h-100 div-shadow-style-1 pointer") : ("card h-100 div-dissable-style-1 pointer")) : ("card h-100 div-shadow-style-1 pointer")} 
                                                                            onClick={() => handleServicesActions(data.productType, data.idProduct, data.idCategory, data.idSubcategory)}
                                                                        >
                                                                            <div className="card-body py-0 d-flex flex-wrap align-content-center">
                                                                                <img
                                                                                    src={data.imgCircle}
                                                                                    alt={data.nameCategory}
                                                                                    style={data.imgCircleStyle}
                                                                                    />
                                                                                <div className="row h-100">
                                                                                    <div className="col-sx-12 col-sm-12 col-md-4 col-lg-4 d-flex flex-wrap align-content-center justify-content-center p-0">
                                                                                        <img className="img-card-responsive" src={data.imgCategory} alt="news" />
                                                                                    </div>
                                                                                    <div className="col-sx-12 col-sm-12 col-md-8 col-lg-8 pt-1 d-flex flex-wrap align-content-center text-justify">
                                                                                        <p className='fs-1-563 font-weight-bold text-blue-sec '>{data.nameCategory}</p>
                                                                                        <p className='fs-875 text-blue-sec '>{data.descCategory}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            <ProductCard
                                                                products={products}
                                                                activeCards={activeCards}
                                                                history={history}
                                                                arrCirclesImg={arrCirclesImg}
                                                                totalSliderInner={sliderInner.length}
                                                            />

                                                        </div>
                                                        {/* {
                                                            showBtns === true ? (
                                                                <Fragment>
                                                                    <button
                                                                        className="carousel-control-prev carousel-a-style-2 border-0 pointer"
                                                                        data-slide="prev"
                                                                        onClick={() => sliderFunction(0)}>
                                                                        <span className="carousel-control-prev-icon carousel-span-style-3"></span>
                                                                    </button>
                                                                    <button
                                                                        className="carousel-control-next carousel-a-style-2 border-0 pointer"
                                                                        data-slide="next"
                                                                        onClick={() => sliderFunction(1)}>
                                                                        <span
                                                                            className="carousel-control-next-icon carousel-span-style-4"></span>
                                                                    </button>
                                                                </Fragment>
                                                            ) : (
                                                                null
                                                            )
                                                        } */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='col-5 mx-auto'>
                                                <PostStructure
                                                    catReactions={catReactions}
                                                    postData={postData}
                                                    tags={tags}
                                                    setPostData={setPostData}
                                                />
                                                {
                                                    showLoader ? (
                                                        <LoaderApp />
                                                    ) : (null)
                                                }
                                            </div> */}
                                        </div>
                                        {/* <CreatePost
                                            setPostData={setPostData}
                                        /> */}
                                    </div>
                                    <Footer />
                                </div>
                            </div>
                            {/*MODAL*/}
                            {/*<!-- Tutorial modal -->*/}
                            <div className="modal" id="tourModal" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                                <div className="modal-dialog modal-dialog-centered modal-lg">
                                    <div className="modal-content" style={{ border: 'none', background: 'transparent' }}>
                                        {/*<!-- Modal body -->*/}
                                        <div className="modal-body">
                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-5 mx-auto">
                                                <div className="card img-fluid" style={{ background: 'transparent', boxShadow: 'none' }}>
                                                    <img className="card-img-top" src="./../img/imagesAdminPanel/iconos_guay-57.png" alt="guay-digital" />
                                                    <div className="card-img-overlay">
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                            <p className="font-medium-1 text-center text-white ">¡Bienvenido a guay!</p>
                                                        </div>
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                            <p className="font-medium-1 text-center text-white ">Acompañanos a conocer nuestra plataforma</p>
                                                        </div>
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 font-medium-1 d-flex justify-content-around">
                                                            <span className="float-left btn-text-1" onClick={noIntro} >Omitir este paso</span>
                                                            <span className="float-right btn-text-2 font-weight-bold" onClick={startIntro} >Siguiente</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*MODAL*/}
                            {/*<!-- Surveys modal -->*/}
                            <SurveyModalKiosk
                                general={general}
                                listCategory={listCategory}
                            />
                            {/*MODAL*/}
                            {/*<!-- Complete information modal -->*/}
                            <div className="modal" id="modal-1" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                                <div className="modal-dialog modal-dialog-centered modal-lg">
                                    <div className="modal-content col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                        {/*<!-- Modal body -->*/}
                                        <div className="modal-body modal-div-style-1 container-style-1 pl-0 pr-0">
                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="row d-flex justify-content-center">
                                                    <div className="col-sx-3 col-sm-3 col-md-3 col-lg-3">
                                                        <img className="img-responsive" src="./../img/imagesProfile/iconos_guay-54.png" alt="guay-digital" />
                                                    </div>
                                                    <div className="col-sx-7 col-sm-7 col-md-7 col-lg-7 d-flex justify-content-center flex-wrap align-content-center">
                                                        <p className="text-blue text-center font-medium-5">Para brindarte una mejor experiencia completa la información de tu perfil de usuario</p>
                                                        <button className="btn btn-blue-2 btn-sm mb-1 pl-5 pr-5 btn-align-center" style={{ bottom: '0' }} id="close-1" onClick={(e) => closeModal(e)}>Continuar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*MODAL*/}
                            {/*<!-- Profile edit modal -->*/}
                            <GenInfoModal />
                            {/* <ProfileModal
                                setProfileData={setProfileData}
                                renderFilePers={renderFilePers}
                                onChangeHandler={onChangeHandler}
                                updateSetData={updateSetData}
                                setData={setData}
                                imgProfile={imgProfile}
                            /> */}
                            <ActMonCollabForm />
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}


const mapStateToProps = ({ general }) => {
    return {
        general
    }
}

export default connect(mapStateToProps)(InicioColaborador);