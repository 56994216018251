import React from 'react'
import $ from "jquery"

const ATSDEMO = ({
    checkboxVal,
    checkboxFunc,
    enterpriseLogo,
}) => {
    return (
        <div
            className="modal"
            id="modal-1"
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            data-backdrop="false"
        >
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div
                    className="modal-content col-sx-10 mx-auto"
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                    }}
                >
                    <div className="modal-body modal-div-bodered container-style-1 pb-5">
                        <div className="col-12 d-flex justify-content-end">
                            <img
                                src={enterpriseLogo}
                                className="w-25"
                                alt='logo'
                            />
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                            <img
                                src="../img/Mensaje-encuesta_1.png"
                                className="w-60"
                                alt='logo'
                            />
                        </div>
                        <div className="col-12 font-medium-1 mt-1">
                            <p className="text-justify">
                                Empezarás con la encuesta de {''}
                                <span className='text-pink-mine font-weight-bold'>Acontecimientos Traumáticos Severos (ATS)</span> {''}
                                la cual se divide en dos bloques: en el primero se identifican los {''}
                                <span className='text-pink-mine font-weight-bold'>acontecimientos (ATS) ocurridos durante y con motivo del trabajo</span>
                                que desempeñas actualmente. Posteriormente te preguntaremos por los ATS pero que sucedieron {''}
                                <span className='text-pink-mine font-weight-bold'>fuera de tu jornada laboral o en tu trabajo anterior</span>.
                            </p>
                            <div className="col-12 pl-5">Estos dos bloques de preguntas se agrupan de la siguiente manera:
                                <p className='mt-1'>1. El tipo de acontecimiento <span className="font-weight-bold">(no importa hace cuanto lo sufriste)</span></p>
                                <p>2. Recuerdos persistentes sobre el acontecimiento  <span className="font-weight-bold">(durante el último mes)</span></p>
                                <p>3. Circunstancias parecidas o asociadas al acontecimiento  <span className="font-weight-bold">(durante el último mes)</span></p>
                                <p>4. Las afectaciones <span className="font-weight-bold">(durante el último mes)</span></p>
                            </div>
                            <p className="text-justify">
                                En la segunda encuesta, preguntaremos algunos {''}
                                <span className='text-pink-mine font-weight-bold'>datos demográficos</span> {''}
                                para conocerte mejor: edad, tiempo de traslado a tu lugar de trabajo, años en la organización, etc.
                            </p>
                            <p className="text-justify">
                                Es importante que estés concentrado y {''}
                                <span className='text-pink-mine font-weight-bold'>seas sincero</span> {''}
                                al momento de responder, recuerda que el objetivo de esto es mejorar tu bienestar emocional.
                            </p>
                            <p className="text-justify">
                                Este cuestionario da {''}
                                <span className='text-pink-mine font-weight-bold'>cumplimiento a la NOM-035</span> {''}
                                de la Secretaría del Trabajo por lo que es de {''}
                                <span className='text-pink-mine font-weight-bold'>carácter obligatorio</span>
                                , la información obtenida será tratada de manera confidencial y los resultados serán exclusivamente para {''}
                                <span className='text-pink-mine font-weight-bold'>fines de mejora</span> {''}
                                en tu estado emocional.
                            </p>
                            <p className="text-justify">Es necesario responder todos los reactivos para que el sistema nos permita obtener resultados confiables.</p>
                            <p className="text-justify">Agradecemos tu colaboración</p>
                        </div>
                        <div className="col-12 mt-1">
                            <div className="form-group form-check">
                                <label className="form-check-label pointer font-medium-1">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        value=""
                                        onChange={() => checkboxFunc()}
                                    />
                                    Al dar click aceptas nuestro {''}
                                    <span
                                        className="text-blue font-weight-bold"
                                        onClick={() => window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf")}
                                    >aviso de privacidad</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-12 mt-1">
                            {checkboxVal === true && (
                                <button
                                    className="btn btn-sm btn-blue-3 float-right font-medium-1"
                                    onClick={() => $('#modal-1').hide()}
                                >Encuesta</button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ATSDEMO